/* eslint-disable no-param-reassign */
/**
 * request 网络请求工具
 * 更详细的 api 文档 https://github.com/umijs/umi-request
 */
import { request, formatMessage } from 'umi';
import { notification } from 'antd';
// import { get } from 'lodash';
// import { async } from 'q';
import token from './token';
import { get } from 'lodash';

const codeMessage = {
  200: 'Successfully updated.',
  201: 'Successfully updated.',
  202: 'Your request is queued.',
  204: 'Successfully removed.',
  400: 'Oops!',
  401: 'Your session is expired. Please login again.',
  403: 'You are not authorized for the request.',
  404: 'Entity not found.',
  406: 'Invalid format.',
  409: 'Oops!',
  410: 'Request entity does not exist.',
  422: 'Validation error.',
  500: 'Oops!',
  502: 'NAT error.',
  503: 'Service is currently unavailable. Please try again later.',
  504: 'Your request has timeouted. Please try again later',
};

const descriptions = {
  400: 'Something was not right. Please try again.',
  409: 'Something went wrong. Please refresh your page and try again.',
  500: 'Something went wrong. Please refresh your page and try again.',
};

/**
 * 异常处理程序
 */
export async function errorHandler(error, intl) {
  if (!error || !error.response) {
    // network error
    notification.error({
      message: codeMessage[500],
      description: descriptions[500],
    });
    return;
  }
  const { response } = error;
  const errortext = codeMessage[response.status] || response.statusText;
  const { status } = response;
  let body;
  try {
    body = await response.json();
  } catch (err) {
    body = undefined;
  }
  if (status === 401 || status === 403) {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login?session_expired=1';
    }
    return body;
  }
  let description = descriptions[status];
  if (body) {
    const errorMessages = getFormattedErrorMessages(body);
    if (errorMessages && errorMessages.length > 0) {
      description = intl
        ? intl.formatMessage({
            id: errorMessages[0].id,
            defaultMessage: errorMessages[0].message,
          })
        : errorMessages[0].message;
    } else if (body.message && typeof body.message === 'string') {
      // TODO: 不支持刷新,暂时未找到 umi 的方法
      description = formatMessage({
        id: body.message,
        defaultMessage: body.message,
      });
    }
    if (!description && typeof body === 'string') {
      description = body;
    }
  }
  notification.error({
    message: errortext,
    description,
  });
}

// return [{ id: string, message: string }]
export function getFormattedErrorMessages(response) {
  if (!response || !response.error) return;
  const messages = get(response, 'data[0].messages');
  if (!messages || !messages.length || !Array.isArray(messages) || !messages[0].message) return;
  return messages;
}

export function buildAuthorization() {
  const tokenVal = token.get();
  return tokenVal ? `Bearer ${tokenVal}` : '';
}

export function trimParams(params) {
  Object.keys(params).forEach(
    k => (params[k] = typeof params[k] == 'string' ? params[k].trim() : params[k]),
  );
  return params;
}

export default request;
