export default {
  'contact_changes.create': '{user} created the contact',
  'contact_changes.update': '{user} updated the contact information',
  'contact_changes.submit': '{user} submitted contact',
  'contact_changes.delete': '{user} deleted the contact',
  'contact_changes.enable': '{user} enabled the contact',
  'contact_changes.disable': '{user} disabled the contact',
  'contact_changes.add_doc': '{user} added document(s) for the contact',
  'contact_changes.delete_doc': '{user} deleted document(s) for the contact',
  'contact_changes.add_memo': '{user} added memo for the contact',
  'contact_changes.delete_memo': '{user} deleted memo for the contact',
  'contact_changes.email_local_statement': 'Emailed local statement to the contact',
};
