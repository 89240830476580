export default {
  'transaction_changes.create': '{user} created the transaction',
  'transaction_changes.update': '{user} updated the transaction information',
  'transaction_changes.submit': '{user} confirmed transaction',
  'transaction_changes.delete': '{user} deleted the transaction',
  'transaction_changes.email': '{user} emailed the transaction',
  'transaction_changes.void': '{user} voided the transaction',
  'transaction_changes.reconcile': '{user} reconciled the transaction',
  'transaction_changes.unreconcile': '{user} unreconciled the transaction',
};
