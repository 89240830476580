
export default {
  'memos.field.subject': 'Subject',
  'memos.field.created_by': 'Created By',
  'memos.field.created_at': 'Created At',
  'memos.field.updated_at': 'Updated At',
  'memos.empty': 'No Memo',
  'memos.title': 'Memo',
  "memos.delete.confirm.title": "Are you sure to delete this memo?",
  "memos.deleted.success": "Successfully deleted Memo",
  "memos.delete.tooltip": "Delete Memo",
  "memos.added.success": "Successfully added Memo",
  "memos.updated.success": "Successfully updated Memo",
  "memos.edit_memo.tooltip": "Edit Memo",




};
