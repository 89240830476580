export default {
  'report.account_aging.title': 'Account Aging Report',
  'report.account_payable_aging.title': 'Account Payable Aging Report',
  'report.account_receivable_aging.title': 'Account Receivable Aging Report',
  'report.account_payables_aging.download.title': 'Download report in excel file format',
  'report.employee.shipment_title': 'Shipments By Employee Report',
  'report.employee.containers_title': 'Containers By Employee Report',
  'report.employee.add.title': 'Add Employee',
  'report.revenue.top_income.title': 'Top 5 Income',
  'report.revenue.top_expense.title': 'Top 5 Expense',
  'report.revenue.revenue.title': 'Revenue Report',
  'report.shipment.top_consignee.title': 'Top 5 Consignees By HBLs',
  'report.shipment.top_shipper.title': 'Top 5 Shippers By HBLs',
  'report.shipment.top_customer.title': 'Top 5 Customers By HBLs',
  'report.shipment.top_billto.title': 'Top 5 BillTos By HBLs',
  'report.shipment.top_agents.title': 'Top 5 Agents By MBLs',
  'report.shipment.top_carriers.title': 'Top 5 Carriers By MBLs',
  'report.daily_cash.series.cash': 'Checking Account',
  'report.daily_cash.series.credit': 'Line of Credit',
  'report.current_account.series.difference': 'Difference',
  'report.shipment.meta.amount': 'Profits',
  'report.shipment.meta.teus': 'TEU',
  'report.shipment.meta.customers': 'Customers',
  'report.shipment.meta.shipments': 'Shipments',
  'report.shipment.meta.containers': 'Containers',
  'report.shipment.meta.hbls': 'HBLs',
  'report.tax_report.supply': 'Supply',
  'report.tax_report.purchase': 'Purchase',
};
