import { DvaModelBuilder, actionCreatorFactory } from 'dva-model-creator';
import { UserInfoInterface } from '@/common/type';

export interface MemberModel {
  operators: UserInfoInterface[] | null;
  sales: UserInfoInterface[] | null;
}

const actionCreator = actionCreatorFactory('member');
const saveData = actionCreator<{
  operators?: UserInfoInterface[];
  sales?: UserInfoInterface[];
}>('saveData');

const initState: MemberModel = {
  operators: null,
  sales: null,
};

export default new DvaModelBuilder(initState, 'member')
  .case(saveData, (state, data) => ({
    ...state,
    ...data,
  }))
  .build();

export const actions = {
  saveData,
};
