import { Dispatch } from 'redux';
import { History } from 'history';

export interface LayoutModel {
  isMobile?: boolean;
  background?: string;
}
export enum UserType {
  owner = 'owner',
  member = 'member',
  agent = 'agent',
  customer = 'customer',
  ams_agent = 'ams_agent',
}

export enum UserRoles {
  admin = 'admin',
  operator = 'operator',
  sales = 'sales',
  // approve_charge_item = 'approve_charge_item',
  // view_report = 'view_report',

  // view_accounting = 'view_accounting',
  // able_archive_shipment = 'able_archive_shipment',
  // able_delete_shipment = 'able_delete_shipment',
  view_staging = 'view_staging',
  able_bank_reconcile = 'able_bank_reconcile',
  lock_arap = 'lock_arap',
  unlock_arap = 'unlock_arap',
  // shop group support
  todos = 'todos',
  inbox = 'inbox',
  pending_shipments = 'pending_shipments',
  access_shipment = 'access_shipment',
  new_shipment = 'new_shipment',
  able_delete_shipment = 'able_delete_shipment',
  edit_shipment = 'edit_shipment',
  lock_shipment = 'lock_shipment',
  unlock_shipment = 'unlock_shipment',
  able_archive_shipment = 'able_archive_shipment',
  copy_shipment = 'copy_shipment',
  transfer_shipment = 'transfer_shipment',
  add_hbl = 'add_hbl',
  copy_hbl = 'copy_hbl',
  delete_hbl = 'delete_hbl',
  shipment_accounting = 'shipment_accounting',
  add_charge_item = 'add_charge_item',
  approve_charge_item = 'approve_charge_item',
  create_invoice = 'create_invoice',
  edit_invoice = 'edit_invoice',
  delete_invoice = 'delete_invoice',
  email_invoice = 'email_invoice',
  make_payment = 'make_payment',
  void_invoice = 'void_invoice',
  shipment_documents = 'shipment_documents',
  upload_shipment_document = 'upload_shipment_document',
  delete_shipment_document = 'delete_shipment_document',
  shipment_status = 'shipment_status',
  access_isf = 'access_isf',
  new_isf = 'new_isf',
  edit_isf = 'edit_isf',
  submit_isf = 'submit_isf',
  delete_isf = 'delete_isf',
  access_contacts = 'access_contacts',
  new_contact = 'new_contact',
  edit_contact = 'edit_contact',
  disable_contact = 'disable_contact',
  delete_contact = 'delete_contact',
  contact_documents = 'contact_documents',
  upload_contact_document = 'upload_contact_document',
  delete_contact_document = 'delete_contact_document',
  contact_status = 'contact_status',
  contact_emails = 'contact_emails',
  view_accounting = 'view_accounting',
  open_invoices = 'open_invoices',
  paid_invoices = 'paid_invoices',
  all_invoices = 'all_invoices',
  new_general_invoice = 'new_general_invoice',
  open_bills = 'open_bills',
  daily_bills = 'daily_bills',
  paid_bills = 'paid_bills',
  all_bills = 'all_bills',
  new_general_bill = 'new_general_bill',
  all_dc_notes = 'all_dc_notes',
  new_dc_note = 'new_dc_note',
  open_statements = 'open_statements',
  all_statements = 'all_statements',
  new_statement = 'new_statement',
  edit_statement = 'edit_statement',
  add_to_statement = 'add_to_statement',
  remove_from_statement = 'remove_from_statement',
  reconcile_in_statement = 'reconcile_in_statement',
  delete_statement = 'delete_statement',
  email_statement = 'email_statement',
  export_statement_excel = 'export_statement_excel',
  pending_incoming_transactions = 'pending_incoming_transactions',
  all_incoming_transactions = 'all_incoming_transactions',
  all_outgoing_transactions = 'all_outgoing_transactions',
  bank_reconciliation = 'bank_reconciliation',
  general_journals = 'general_journals',
  new_journal_entry = 'new_journal_entry',
  edit_journal_entry = 'edit_journal_entry',
  delete_journal_entry = 'delete_journal_entry',
  lock_journal_entry = 'lock_journal_entry',
  unlock_journal_entry = 'unlock_journal_entry',
  batch_lock_unlock = 'batch_lock_unlock',
  batch_lock_unlock_all = 'batch_lock_unlock_all',
  charge_item_codes = 'charge_item_codes',
  new_charge_item_code = 'new_charge_item_code',
  edit_charge_item_code = 'edit_charge_item_code',
  charge_item_templates = 'charge_item_templates',
  new_charge_item_template = 'new_charge_item_template',
  edit_charge_item_template = 'edit_charge_item_template',
  delete_charge_item_template = 'delete_charge_item_template',
  bank_accounts = 'bank_accounts',
  new_bank_account = 'new_bank_account',
  edit_bank_account = 'edit_bank_account',
  delete_bank_account = 'delete_bank_account',
  currency_rates = 'currency_rates',
  view_report = 'view_report',
  shipment_pl_report = 'shipment_pl_report',
  account_reports = 'account_reports',
  company_statements = 'company_statements',
  journal_report = 'journal_report',
  shipment_report = 'shipment_report',
  revenue_report = 'revenue_report',
  manage_users = 'manage_users',
  new_user = 'new_user',
  edit_user = 'edit_user',
  disable_user = 'disable_user',
  employee_report = 'employee_report',
  workflows = 'workflows',
  edit_fapiao = 'edit_fapiao',
  lock_perm = 'lock_perm',
  unlock_perm = 'unlock_perm',
  edit_ams = 'edit_ams',
  edit_aci = 'edit_aci',
  new_quotation = 'new_quotation',
  edit_quotation = 'edit_quotation',
  edit_currency_rate = 'edit_currency_rate',
  year_end_closing = 'year_end_closing',
}
export interface SiteInfo {
  ext_id: string;
  host: string;
  name: string;
  status: string;
  logo: MediaInterface | null;
  settings?: {
    name: string;
    value: string | null;
    enabled?: boolean;
  }[];
  info?: ShopInfo;
  currencies?: Currency[];
}

export interface UserRolesData {
  name: string;
  effect?: string;
  condition?: string;
}

export interface UserInfoInterface {
  id: number;
  username: string;
  name: string;
  type: UserType | null;
  in_offices?: Office[];
}
export interface UserData {
  ext_id: string;
  id: number;
  name: string;
  type: UserType | null;
  blocked: boolean;
  confirmed: boolean;
  email: string;
  shop: SiteInfo;
  shop_roles: UserRoles[];
  shop_roles_data: UserRolesData[];
  username: string;
  umiLocale?: string;
  in_offices: Office[];
  shop_office: number | null;
  feature_multi_offices?: boolean;
}
export interface UserModel {
  status?: boolean;
  data?: UserData;
  error?: string;
  newMessage: boolean;
  showTrialAgreement?: boolean;
}

export interface MediaFormatInterface {
  ext: string;
  height: number;
  width: number;
  size: number;
  url: string;
  name: string;
  mime: string;
}
export interface MediaInterface {
  id: number;
  caption: string | null;
  ext: string;
  height: number;
  width: number;
  size: number;
  url: string;
  previewUrl: string | null;
  name: string;
  mime: string;
  formats: {
    large?: MediaFormatInterface;
    medium?: MediaFormatInterface;
    small?: MediaFormatInterface;
    thumbnail?: MediaFormatInterface;
  };
}

export interface GlobalState {
  user: UserModel;
  layout: LayoutModel;
  loading?: {
    effects: {
      [key: string]: boolean;
    };
  };
}

export interface UmiComponentProps {
  history?: History;
  dispatch: Dispatch<any>;
  route?: Route;
}

export interface LayoutProps {
  isMobile?: boolean;
  onCollapse?: (collapse: boolean) => void;
}

export interface Route {
  path: string;
  name: string;
  icon?: string;
  routes?: Route[];
  hideChildrenInMenu?: boolean;
  hideInMenu?: boolean;
  target?: string;
}

export enum ShipMode {
  FCL = 'FCL',
  LCL = 'LCL',
  CONS = 'CONS',
  BULK = 'BULK',
  AIR = 'AIR',
}
export enum PackageTypes {
  bag = 'bags',
  box = 'boxes',
  bottle = 'bottle',
  bucket = 'bucket',
  bunch = 'bunch',
  bundle = 'bundle',
  bulk = 'bulk',
  carton = 'cartons',
  cask = 'cask',
  case = 'case',
  cage = 'cage',
  chest = 'chest',
  crates = 'crates',
  ctn = 'ctns',
  drum = 'drum',
  ibcs = 'ibcs',
  lot = 'lot',
  other = 'other',
  package = 'packages',
  pack = 'packs',
  pallet = 'pallets',
  parcel = 'parcels',
  piece = 'pieces',
  roll = 'rolls',
  set = 'sets',
  skid = 'skids',
  tank = 'tanks',
  unit = 'units',
}
export enum RoutingTerms {
  AWS = 'AWS',
  MLB = 'MLB',
  IPI = 'IPI',
  RIPI = 'RIPI',
  USWC = 'USWC',
  USEC = 'USEC',
  USGC = 'USGC',
}
export type ShipmentType =
  | 'ocean_import'
  | 'ocean_export'
  | 'air_import'
  | 'air_export'
  | 'misc'
  | 'truck';
export interface Shipment {
  id: number;
  ext_id: string;
  shipment_number: string | null;
  office: Office | null;
  type: ShipmentType;
  ombl: MBL | null;
  hbls: HBL[] | null;
  mode: ShipMode | null;
  operator: UserInfoInterface | null;
  sales: UserInfoInterface | null;
  shipper: Contact | null;
  consignee: Contact | null;
  notify: Contact | null;
  agent: Contact | null;
  carrier: Contact | null;
  transit_carrier: Contact | null;
  trucker: Contact | null;
  coloader: Contact | null;
  hbl_ext_id?: string;
  hbl: Document | null;
  mbl: Document | null;
  eta: string | null;
  eta_hour: string | null;
  ata: string | null;
  etd: string | null;
  etd_hour: string | null;
  atd: string | null;
  port_of_loading: string | null;
  port_of_discharge: string | null;
  vessel: string | null;
  voyage: string | null;
  final_destination: Contact | null;
  shipment_containers: ShipmentContainer[];
  notes: string | null;
  commodity?: string | null;
  pieces?: number | null;
  package?: PackageTypes | null;
  weight?: number | null;
  measure?: number | null;
  todo_instruction?:
    | {
        name: string;
        due_date_field?: string;
        due_date_offset?: number;
        create_after_action?: Action;
        allow_multiple?: boolean;
      }[]
    | null;
  todos: Todo[];
  completed_todos?: Todo[];
  submitted_at: string | null;
  archived_at: string | null;
  changes?: ShipmentChange[];
  hbl_no: string | null;
  mbl_no: string | null;
  ams_no?: string | null;
  final_port_of_discharge?: string | null;
  cargo_location_contact?: Contact | null;
  term: string | null;
  routing_term: RoutingTerms | null;
  po_no: string | null;
  broker: Contact | null;
  isf_no?: string | null;
  it_no?: string | null;
  it_place?: string | null;
  it_date?: string | null;
  container_return_address?: string | null;
  commodity_info?: string | null;
  pickup_number?: string | null;
  ccn?: string | null;
  mbl_payment?: PaymentTerm | null;
  hbl_payment?: PaymentTerm | null;
  mbl_release?: ReleaseMethod | null;
  hbl_release?: ReleaseMethod | null;
  flight: string | null;
  freight_charge_weight?: number | null;
  freight_rate?: number | null;
  freight_charge_level?: string | null;
  freight_charge?: number | null;
  flight_duration?: number | null;
  additional_notify?: Contact | null;
  customs_release_date?: string | null;
  carrier_release_date?: string | null;
  terminal_release_date?: string | null;
  port_last_free_date?: string | null;
  final_eta?: string | null;
  children?: Shipment[] | null;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  block_by: UserInfoInterface | null;
  approvals?: Approval[];
  container_qty?: string[] | null;
  weightUnit?: string | null;
  volumeUnit?: string | null;
  edi_pushed_at?: string | null;
  trackings?: any[] | null;
}

export interface MBL {
  id: number;
  ext_id: string;
  mbl_no: string;

  hbl_no: string | null;
  agent: Contact | null;
  carrier: Contact | null;
  transit_carrier: Contact | null;
  coloader: Contact | null;
  shipper: Contact | null;
  consignee: Contact | null;
  notify: Contact | null;
  additional_notify: Contact | null;
  mode: ShipMode | null;
  term: string | null;
  operator: UserInfoInterface | null;
  co_operators: UserInfoInterface[] | null;
  agent_ref_no: string | null;
  sub_bl_no: string | null;
  carrier_contract_no: string | null;
  eta: string | null;
  etd: string | null;
  transit_eta: string | null;
  transit_etd: string | null;
  ata: string | null;
  atd: string | null;
  etb: string | null;
  final_eta: string | null;
  port_of_discharge: string | null;
  port_of_loading: string | null;
  port_of_transit: string | null;
  vessel: string | null;
  voyage: string | null;
  place_of_delivery: string | null;
  place_of_delivery_original_location: Location | null;
  place_of_delivery_eta: string | null;
  final_destination: string | null;
  final_destination_original_location: Location | null;
  mbl_type: string | null;
  cy_location: Contact | null;
  cfs_location: Contact | null;
  delivery_location: Contact | null;
  trucker: Contact | null;
  pickup_location: Contact | null;
  place_of_receipt: string | null;
  place_of_receipt_original_location: Contact | null;
  freight_payment: PaymentTerm | null;
  obl_type: ReleaseMethod | null;
  is_original_bl_received: boolean | null;
  original_bl_received_date: string | null;
  original_bl_released_date: string | null;
  is_telex_release_received: boolean | null;
  telex_release_received_date: string | null;
  is_release_approved: boolean | null;
  is_released: boolean | null;
  release_date: string | null;
  container_return_location: Contact | null;
  pieces: number | null;
  containers: ShipmentContainer[];
  it_no: string | null;
  it_date: string | null;
  place_of_receipt_etd: string | null;
  it_place: string | null;
  ccn: string | null;
  color: string | null;
  customer_booking_no: string | null;
  invoices?: Invoice[];
  bills?: Bill[];
  dc_notes?: DCNote[];
  is_direct?: boolean | null;
  sales_type?: string | null;
  cargo_type?: string | null;
  customer?: Contact | null;
  bill_to?: Contact | null;
  sales?: UserInfoInterface | null;
  doc_cut_off_time: string | null;
  port_cut_off_time: string | null;
  vgm_cut_off_time: string | null;
  rail_cut_off_time: string | null;
  vessel_imo: string | null;
  vessel_flag: string | null;
  forwarding_agent: Contact | null;
  broker?: Contact | null;
  last_free_date?: string | null;
  arrival_notice_sent_date?: string | null;
  delivery_order_sent_date?: string | null;
  container_qty?: string[] | null;
  quotation: Quotation | null;
}

export enum BookingType {
  ocean_export = 'ocean_export',
  air_export = 'air_export',
}
export interface Booking extends HBL {
  type: BookingType;
  shipment: Shipment;
  booking_no: string;
}

export interface HBL {
  id: number;
  ext_id: string;
  hbl_no: string;
  operator: UserInfoInterface | null;
  co_operators: UserInfoInterface[] | null;
  sales: UserInfoInterface | null;
  customer: Contact | null;
  bill_to: Contact | null;
  shipper: Contact | null;
  consignee: Contact | null;
  notify: Contact | null;
  additional_notify: Contact | null;
  agent: Contact | null;
  trucker: Contact | null;
  broker: Contact | null;
  delivery_trucker: Contact | null;
  container_return_location: Contact | null;
  po_no: string | null;
  ams_no: string | null;
  isf_no: string | null;
  cycfs_location: Contact | null;
  available_date: string | null;
  place_of_delivery: string | null;
  place_of_delivery_original_location: Location | null;
  place_of_delivery_eta: string | null;
  final_destination: string | null;
  final_destination_original_location: Location | null;
  final_eta: string | null;
  delivery_location: Contact | null;
  additional_delivery_location: Contact | null;
  mode: ShipMode | null;
  freight_payment: PaymentTerm | null;
  truck_freight_payment: PaymentTerm | null;
  last_free_date: string | null;
  rail: boolean | null;
  rail_company: string | null;
  it_no: string | null;
  it_date: string | null;
  it_place: string | null;
  go_date: string | null;
  expiry_date: string | null;
  hbl_release: ReleaseMethod | null;
  sales_type: string | null;
  incoterms: string | null;
  cargo_type: string | null;
  is_door_move: boolean | null;
  is_customs_clearance: boolean | null;
  is_customs_hold: boolean | null;
  customs_release_date: string | null;
  term: string | null;
  entry_no: string | null;
  entry_doc_sent_date: string | null;
  is_original_bl_received: boolean | null;
  original_bl_received_date: string | null;
  is_telex_release_received: boolean | null;
  telex_release_received_date: string | null;
  is_release_order_required: boolean | null;
  is_hold: boolean | null;
  hold_notes: string | null;
  hold_reason: string | null;
  is_released: boolean | null;
  release_date: string | null;
  released_by: UserInfoInterface | null;
  door_delivery_date: string | null;
  arrival_notice_sent_date: string | null;
  delivery_order_sent_date: string | null;
  containers: ShipmentContainer[];
  commodity: string | null;
  commodity_info: string | null;
  mark: string | null;
  arrival_notice_remark: string | null;
  delivery_order_remark: string | null;
  package: PackageTypes | null;
  pieces: number | null;
  weight: number | null;
  measure: number | null;
  weightUnit: string;
  volumeUnit: string;
  pickup_number: string | null;
  bg_color?: string;
  mbl?: MBL;
  invoices?: Invoice[];
  bills?: Bill[];
  dc_notes?: DCNote[];
  soc_address: string | null;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  block_by: UserInfoInterface | null;
  commercial_invoice_no: string | null;
  color?: string;
  wh_cut_off_time: string | null;
  loading_location: Contact | null;
  origin_terminal: Contact | null;
  hbl_type: string | null;
  carrier_booking_no: string | null;
  itn_no: string | null;
  is_rated_bl: boolean | null;
  todos?: Todo[];
  forwarding_agent: Contact | null;
  sub_bl_no: string | null;
  pickup_location: Contact | null;
  isf: ISF | null;
  ams: AMS | null;
  container_qty?: string[] | null;
  is_release_approved: boolean | null;
  quotation: Quotation | null;
}

export interface HBLWithShipment extends HBL {
  shipment: Shipment;
}

export interface Location {
  id: number;
  code: string;
  country: string | null;
  country_code: string | null;
  default_display: string | null;
  loc_code: string | null;
  name: string | null;
  subdiv_code: string | null;
  subdiv_id: string | null;
}

export enum ContactTypeValues {
  SHIPPER = 'shipper',
  CONSIGNEE = 'consignee',
  CARRIER = 'carrier',
  NOTIFY = 'notify',
  AGENT = 'agent',
  LOCATION = 'location',
  FORWARDER = 'forwarder',
  BROKER = 'broker',
  WAREHOUSE = 'warehouse',
  TRUCKER = 'trucker',
  CARRIER_AGENT = 'carrier_agent',
  BANK = 'bank',
  COLOADER = 'coloader',
  OTHER = 'other',
}
export interface ContactType {
  id: number;
  value: ContactTypeValues;
}
export interface Contact {
  id: number;
  ext_id: string;
  name: string;
  types: ContactType[];
  short_name: string | null;
  contact: string | null;
  phone: string | null;
  fax: string | null;
  address: string | null;
  city: string | null;
  isf_submission_name: string | null;
  country: string | null;
  notes: string | null;
  sales: UserInfoInterface | null;
  operator: UserInfoInterface | null;
  facet_id?: string;
  is_original: boolean | null;
  from_facet: Contact | null;
  email: string | null;
  disabled: boolean | null;
  deleted_at: string | null;
  auto_send_local_statement: boolean | null;
  accounting_email: string | null;
  manual_print_address: string | null;
  local_name: string | null;
  native_name: string | null;
  tags: ContactTag[] | null;
  billing_address: string | null;
  is_temp: boolean | null;
  code: string | null;
  credit_term_type: string | null;
  credit_days: number | null;
  edi?: Record<string, any> | null;
}

export interface ContactTag {
  id: string | number;
  name: string;
  original_code: string | null;
}

export enum DocumentTypes {
  hbl = 'hbl',
  mbl = 'mbl',
  bl = 'bl',
  air_entry = 'air_entry',
  air_arrival_notice = 'air_arrival_notice',
  arrival_notice = 'arrival_notice',
  carrier_arrival_notice = 'carrier_arrival_notice',
  booking_confirmation = 'booking_confirmation',
  customs_declaration = 'customs_declaration',
  air_delivery_order = 'air_delivery_order',
  delivery_order = 'delivery_order',
  delivery_order_customs = 'delivery_order_customs',
  delivery_receipt = 'delivery_receipt',
  dock_receipt = 'dock_receipt',
  lcl_devanning_notice = 'lcl_devanning_notice',
  invoice = 'invoice',
  statement = 'statement',
  statement_group_by_shipment = 'statement_group_by_shipment',
  local_statement = 'local_statement',
  release_order = 'release_order',
  soc_guarantee = 'soc_guarantee',
  ocean_export_mbl = 'ocean_export_mbl',
  ocean_export_hbl = 'ocean_export_hbl',
  ocean_export_hbl_release = 'ocean_export_hbl_release',
  ocean_export_hbl_confirmation = 'ocean_export_hbl_confirmation',
  ocean_export_hbl_blank = 'ocean_export_hbl_blank',
  ocean_export_mbl_release = 'ocean_export_mbl_release',
  ocean_export_mbl_confirmation = 'ocean_export_mbl_confirmation',
  ocean_export_mbl_blank = 'ocean_export_mbl_blank',
  hawb_blank = 'hawb_blank',
  hawb_confirmation = 'hawb_confirmation',
  mawb_blank = 'mawb_blank',
  mawb_confirmation = 'mawb_confirmation',
  credit_note = 'credit_note',
  debit_note = 'debit_note',
  packing_list = 'packing_list',
  certificate_of_origin = 'certificate_of_origin',
  departure_notice = 'departure_notice',
  cargo_receipt = 'cargo_receipt',
  pickup_order = 'pickup_order',
  bl_instruction = 'bl_instruction',
  transaction_voucher = 'transaction_voucher',
  hbl_confirmation = 'hbl_confirmation',
  hbl_release = 'hbl_release',
  hbl_blank = 'hbl_blank',
  receiving_notice = 'receiving_notice',
  booking_note = 'booking_note',
  other = 'other',
  mbl_release = 'mbl_release',
  mbl_blank = 'mbl_blank',
  bank_receipt = 'bank_receipt',
  invoice_confirmation = 'invoice_confirmation',
  mbl_manifest = 'mbl_manifest',
  mbl_dev_seg = 'mbl_dev_seg',
  payment_voucher = 'payment_voucher',
  isf_report = 'isf_report',
  aci_report = 'aci_report',
  profit_report = 'profit_report',
  tax_report = 'tax_report',
  quotation_report = 'quotation_report',
}
export interface Document {
  id: number;
  ext_id: string;
  name: string;
  descriptions: DocumentTypes | null;
  type: DocumentTypes;
  hbl: number | null;
  mbl: number | null;
  contact: number | null;
  files: MediaInterface[];
  telex: boolean | null;
  user: UserInfoInterface | null;
  private: boolean | null;
  additional_info: Record<string, any> | null;
  generated_by_sf: boolean | null;
  shipment: number | null;
  isf: number | null;
}

export interface Memo {
  id: number;
  ext_id: string;
  subject: string;
  descriptions: string | null;
  user: UserInfoInterface | null;
}

export interface DocumentDetails extends Omit<Document, 'contact'> {
  contact: Contact | null;
}

export enum WeightUnits {
  KG = 'KG',
  LB = 'LB',
}

export enum RateClasses {
  M = 'M',
  N = 'N',
  Q = 'Q',
}

export enum AirCharges {
  PPD = 'PPD',
  COLL = 'COLL',
}

export enum VolumeUnits {
  CBM = 'CBM',
  CFT = 'CFT',
}
export enum SizeUnits {
  CM = 'CM',
  IN = 'IN',
}
export enum ContainerSize {
  s12 = '12',
  s20 = '20',
  s40 = '40',
  s45 = '45',
  s46 = '46',
  s48 = '48',
  s53 = '53',
}

export enum ContainerTypes {
  AC = 'AC',
  BK = 'BK',
  DC = 'DC',
  FL = 'FL',
  FR = 'FR',
  FX = 'FX',
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  G4 = 'G4',
  GH = 'GH',
  GOH = 'GOH',
  GP = 'GP',
  GX = 'GX',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  HC = 'HC',
  HG = 'HG',
  HH = 'HH',
  HQ = 'HQ',
  HR = 'HR',
  HX = 'HX',
  IH = 'IH',
  LQ = 'LQ',
  MT = 'MT',
  MU = 'MU',
  NOR = 'NOR',
  OH = 'OH',
  OS = 'OS',
  OT = 'OT',
  PL = 'PL',
  RF = 'RF',
  RQ = 'RQ',
  RH = 'RH',
  SJ = 'SJ',
  TF = 'TF',
  TK = 'TK',
  VT = 'VT',
  XX = 'XX',
}

export enum ContainerAMSTypes {
  '53 HQ (9400)' = '9400',
  '53 HQ (9500)' = '9500',
  '20 GP' = '20G0',
  '40 GP' = '40G0',
  '45 GP' = 'L0G0',
  '20 HQ' = '20R0',
  '40 HQ' = '40R0',
  '45 HQ' = 'L0R0',
  '20 SPECIAL EQUIP' = '20U0',
  '40 SPECIAL EQUIP' = '40U0',
}

export enum ContainerGroupCodes {
  "20'" = '20',
  "40'" = '40',
  "45'" = '45',
  'HC' = 'HC',
  'RF' = 'RC',
  'SOC' = 'SOC',
}

export enum PackageAMSCode {
  'Bag' = 'bag',
  'Bal' = 'bal',
}

export enum PackageEManifestCode {
  'AMM' = 'amm',
  'ATH' = 'ath',
  'BAG' = 'bag',
}

export interface ShipmentContainer {
  id: number;
  ext_id: string;
  name: string | null;
  type: string | null;
  pieces: number | null;
  weight: number | null;
  size: number | null;
  measure: number | null;
  pickup_number: string | null;
  pickup_date: string | null;
  seal_number: string | null;
  move_date: string | null;
  delivery_date: string | null;
  shipment?: number;
  return_date: string | null;
  soc: boolean | null;
  mark: string | null;
  last_free_date: string | null;
  it_number: string | null;
  discharged_date: string | null;
  size_length?: number | null;
  size_width?: number | null;
  size_height?: number | null;
  size_unit?: string | null;
}

export enum TodoTypes {
  assign = 'assign',
  arrival_notice = 'arrival_notice',
  ams_isf = 'ams_isf',
  customs_clearance = 'customs_clearance',
  confirm_customs_released = 'confirm_customs_released',
  delivery_order = 'delivery_order',
  confirm_delivery_order_received = 'confirm_delivery_order_received',
  carrier_release = 'carrier_release',
  confirm_carrier_released = 'confirm_carrier_released',
  confirm_terminal_released = 'confirm_terminal_released',
  it = 'it',
  release_status = 'release_status',
  booking_confirmation = 'booking_confirmation',
  delivered = 'delivered',
  hbl = 'hbl',
  moving = 'moving',
  pickup_number = 'pickup_number',
  pickup_last_free_date = 'pickup_last_free_date',
  container_return = 'container_return',
  confirm_container_returned = 'confirm_container_returned',
  container_pickup = 'container_pickup',
  confirm_container_pickedup = 'confirm_container_pickedup',
  container_return_free_date = 'container_return_free_date',
  terminal_free_date = 'terminal_free_date',
  accept_payment = 'accept_payment',
  confirm_payment = 'confirm_payment',
  release_order = 'release_order',
}

export interface ZodNotification {
  name: string;
  path: string;
  issue: string;
}

export interface Todo {
  id: number;
  name: string;
  due_date: string | null;
  completed_at: string | null;
  created_at: string;
  documents?: Document[];
  additional_info: Record<string, any> | null;
  mbl: number | null;
  hbl: number | null;
}

export enum ActionTypes {
  create = 'create',
  update = 'update',
  submit = 'submit',
  archive = 'archive',
  delete_hbl = 'delete_hbl',
  add_todo = 'add_todo',
  update_todo = 'update_todo',
  complete_todo = 'complete_todo',
  delete_todo = 'delete_todo',
  add_document = 'add_document',
  update_document = 'update_document',
  delete_document = 'delete_document',
  assign = 'assign',
  add_charge_item = 'add_charge_item',
  remove_charge_item = 'remove_charge_item',
  approve_charge_item = 'approve_charge_item',
  duplicate = 'duplicate',
  void_transaction = 'void_transaction',
  void_invoice = 'void_invoice',
  void_bill = 'void_bill',
  reconcile_invoice = 'reconcile_invoice',
  reconcile_bill = 'reconcile_bill',
  add_memo = 'add_memo',
  update_memo = 'update_memo',
  delete_memo = 'delete_memo',
  submit_approved = 'submit_approved',
  submit_rejected = 'submit_rejected',
  submit_canceled = 'submit_canceled',
  locked = 'locked',
  unlocked = 'unlocked',
  submit_release_request = 'submit_release_request',
  release_approved = 'release_approved',
  release_rejected = 'release_rejected',
  release_canceled = 'release_canceled',
  push_edi = 'push_edi',
}
export interface Action {
  type: ActionTypes;
  data?: Record<string, any>;
  changes?: Record<string, { prev: any; next: any } | { added: number[]; removed: number[] }>;
}
export type ChangelogModel =
  | 'dc-notes'
  | 'bills'
  | 'invoices'
  | 'journal-entries'
  | 'hbl'
  | 'mbl'
  | 'isf'
  | 'statements'
  | 'ams'
  | 'transactions'
  | 'shipment'
  | 'contact'
  | 'bookings'
  | 'quotations';
export interface BaseChanges {
  id: number;
  type: ActionTypes;
  payload: Action[] | Record<string, any> | null;
  user: UserInfoInterface | null;
  created_at: string;
}

export interface ShipmentChange extends BaseChanges {
  is_accounting: boolean | null;
}

export interface Email {
  id: number;
  creator: UserInfoInterface | null;
  subject: string;
  from_email: string;
  to_email: string;
  created_at: string;
  sent_at: string;
  attachments: string;
}
export interface ContactChange {
  id: number;
  type: ActionTypes;
  payload: Action[] | null;
  user: UserInfoInterface | null;
  created_at: string;
}

export interface TransactionChange {
  id: number;
  type: ActionTypes;
  payload: Action[] | null;
  user: UserInfoInterface | null;
  created_at: string;
}

export interface ShopInfo {
  address: string | null;
  phone: string | null;
  fax: string | null;
  email: string | null;
}

export interface ChargeItemCode {
  ext_id: string;
  code: string;
  name: string;
  default_currency: string | null;
  local_name: string | null;
  description: string | null;
  price: number;
  type: 'income' | 'expense' | 'income_or_expense' | null;
  office: Office | null;
  income_gl_code: GLCode | null;
  expense_gl_code: GLCode | null;
  credit_note_gl_code: GLCode | null;
  debit_note_gl_code: GLCode | null;
  tax_code: TaxCode | null;
}

export interface ChargeItem {
  id: number;
  ext_id: string;
  code: string | null;
  name: string | null;
  local_name: string | null;
  description: string | null;
  price: number | null;
  vol: number | null;
  freight_payment: string | null;
  type: 'income' | 'expense';
  credit: number | null;
  credit_in_currency: number | null;
  debit: number | null;
  debit_in_currency: number | null;
  status: string | null;
  creator: UserInfoInterface | null;
  contact: Contact | number | null;
  target_contact_field: string | null;
  hbl: number | null;
  currency: string;
  invoice?: Invoice | null;
  bill?: Bill | null;
  dc_note?: DCNote | null;
  booking?: number | null;
  amount?: number;
  paid_amount?: number;
  balance?: number;
  remaining_amount_in_currency: number | null;
  tax_code: TaxCode | null;
  tax: number | null;
  tax_in_currency: number | null;
  tax_rate: number | null;
  wht: number | null;
  wht_in_currency: number | null;
  wht_rate: number | null;
  wht_code: TaxCode | null;
}

export enum ChargeItemStatus {
  draft = 'draft',
  submitted = 'submitted',
  approved = 'approved',
  locked = 'locked',
  void = 'void',
}

export type InvoiceStatus =
  | 'draft'
  | 'open'
  | 'partially_paid'
  | 'pending_paid'
  | 'paid'
  | 'void'
  | 'reconciled';
export interface Invoice {
  id: number;
  ext_id: string;
  contact: Contact | null;
  date: string | null;
  due_date: string | null;
  creator: UserInfoInterface | null;
  credit: number;
  debit: number;
  charge_items: ChargeItem[];
  documents: Document[] | null;
  shipment: Shipment | null;
  booking: Booking | null;
  status: InvoiceStatus;
  code: string | null;
  void_at: string | null;
  payments: Payment[];
  pending?: number;
  balance?: number;
  reconciled: boolean | null;
  hbl: number | null;
  doc_type?: string;
  color: string | null;
  mbl_no?: string;
  memo?: string;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  block_by: UserInfoInterface | null;
  office: Office | null;
  currency_rate_source: 'setting' | 'override';
  currency_rate_override: Record<string, any> | null;
  created_at: string | null;
  language: string | null;
  tax_codes?: TaxCode[];
  tax?: number;
}

export interface Bill extends Invoice {
  is_ready: boolean | null;
  dependents?: (Partial<Invoice> | string)[];
}

export type TransactionType = 'check' | 'cash' | 'ach' | 'check_copy' | 'wire';
export type TransactionDirection = 'incoming' | 'outgoing';
export type TransactionPaymentAmountType = {
  type: 'invoice' | 'bill' | 'dc_note' | 'gl';
  key: string;
  ext_id: string;
  amount: number;
  amount_in_currency: number;
  transaction_amount_in_currency: number;
  charge_amount: number;
  charge_amount_in_currency: number;
  invoice?: Invoice;
  bill?: Bill;
  dc_note?: DCNote;
  currency: string;
  paid_amount: number;
  paid_amount_in_currency: number;
  date: string;
  due_date: string | null;
  code: string;
  shipment_number: string | null;
  hbl_no: string | null;
  mbl_no: string | null;
  eta: string | null;
  etd: string | null;
  contact: Contact | null;
  office?: Office | null;
  gl_code: GLCode | null;
};
export interface Transaction {
  ext_id: string;
  type: TransactionType;
  contact: Contact | null;
  creator: UserInfoInterface | null;
  credit: number;
  credit_in_currency: number;
  debit: number;
  debit_in_currency: number;
  void_at: string | null;
  date: string | null;
  code: string | null;
  description: string | null;
  photo: MediaInterface | null;
  submitted_at: string | null;
  submitted_by: UserInfoInterface | null;
  reconciled: boolean | null;
  notes: string | null;
  account: Account | null;
  currency: string;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  block_by: UserInfoInterface | null;
  direction: TransactionDirection;
  currency_rate_override: Record<string, any> | null;
  payment_amounts?: TransactionPaymentAmountType[];
  office: Office | null;
}
export interface Payment {
  amount: number;
  pending: boolean;
  contact_account_ledger: number | null;
  tx: string | null;
  currency: string;
  amount_in_currency: number;
}

export interface Port {
  id: string | number;
  name: string;

  code: string | null;
  country: string | null;
  state: string | null;
  types: string | null;
}

export interface Vessel {
  id: string | number;
  name: string;
  country: string | null;
  flag: string | null;
}

export interface ContainerMove {
  date: string | null;
  moves: string | null;
  location: string | null;
  vessel_voyage: string | null;
}

export type PaymentTerm = 'PP' | 'CC' | 'PREPAID' | 'COLLECT';
export type ReleaseMethod = 'original' | 'telex' | 'seaway_bill' | 'e_bill';

export enum PaymentTermEnum {
  PREPAID = 'PREPAID',
  COLLECT = 'COLLECT',
}

export interface AccountAging {
  ext_id: string;
  name: string;
  balances: {
    min_days: number;
    max_days?: number;
    balance: number;
  }[];
}

export interface Statement {
  receivable_sum_in_currencies: Record<string, any>[];
  payable_sum_in_currencies: Record<string, any>[];
  ext_id: string;
  contact: Contact | null;
  start_date: string | null;
  end_date: string | null;
  created_at: string;
  receivable_sum: number;
  payable_sum: number;
  total: number;
  balance: number;
  pending_payment_sum: number;
  status: string;
  bills: Bill[];
  invoices: Invoice[];
  payments: Payment[];
  shipments?: Shipment[];
  charge_items?: ChargeItem[];
  selected_charge_items?: ChargeItem[];
  creator: UserInfoInterface | null;
  documents: Document[];
  dc_notes: DCNote[];
  date: string | null;
  reconciled: boolean | null;
  reconciled_at: string | null;
  reconciled_by: UserInfoInterface | null;
  total_in_currencies?: Record<string, any>[];
  balance_in_currencies?: Record<string, any>[];
  approved_at: string | null;
  approved_by: UserInfoInterface | null;
  approvals?: Approval[];
  code: string | null;
  color: string | null;
  po_no_list: string[] | null;
  mbl_no_list: string[] | null;
  hbl_no_list: string[] | null;
  group?: 'invoice' | 'shipment' | 'charge_item';
  fapiaos?: Fapiao[];
  request_fapiao_by?: UserInfoInterface | null;
  request_fapiao_at?: string | null;
  type: 'payable' | 'receivable';
  reconciled_items?: (Invoice | Bill | DCNote)[];
  incl_offices?: Office[];
  office?: Office | null;
}

export interface ChargeItemTemplate {
  ext_id: string;
  name: string;
  creator: UserInfoInterface | null;
  receivable_items: ChargeItem[];
  payable_items: ChargeItem[];
}

export interface Memo {
  id: number;
  subject: string;
  content: string | null;
  created_at: string;
  updated_at: string;
  created_by_user: UserInfoInterface | null;
}

export type MemosTypeEnum =
  | 'bill'
  | 'dc-note'
  | 'invoice'
  | 'mbl'
  | 'hbl'
  | 'shipment'
  | 'transaction'
  | 'statement'
  | 'journal-entry'
  | 'inbox-item'
  | 'document'
  | 'booking'
  | 'quotation';

export enum DocumentModelEnum {
  bill = 'bill',
  'dc-note' = 'dc-note',
  invoice = 'invoice',
  mbl = 'mbl',
  hbl = 'hbl',
  statement = 'statement',
  shipment = 'shipment',
  transaction = 'transaction',
  booking = 'booking',
  'journal-entry' = 'journal-entry',
  quotation = 'quotation',
  isf = 'isf',
  aci = 'aci',
  ams = 'ams',
  'inbox-item' = 'inbox-item',
}

export enum ISFStatus {
  new = 'new',
  submitted = 'submitted',
  received = 'received',
}

export interface ISF {
  id: number;
  ext_id: string;
  ams_no: string;
  name: string;
  isf_type: string;
  status: ISFStatus;
  eta: string;
  etd: string;
  isf_no: string;
  last_disposition: string;
  last_disposition_at: string;
  last_ams_match_status: string;
  last_ams_match_status_at: string;
}

export interface Fapiao {
  id: number;
  ext_id: string;
  type: string | null;
  code: string | null;
  number: string | null;
  contact: Contact | null;
  amount: number;
  tax: number;
  issue_date: string | null;
  verification_code: string | null;
  remark: string | null;
  creator: UserInfoInterface | null;
  statement?: Statement;
  documents: Document[];
}

export enum AMStatus {
  new = 'new',
  submitted = 'submitted',
  received = 'received',

  update = 'update',
}

export interface AMS {
  id: number;
  ams_no: string;
  ext_id: string;
  manifest_id: string;
  scac: string;
  vessel: string | null;
  voyage: string | null;
  status: AMStatus;
  vessel_country_code: string | null;
  port_of_discharge: string | null;
  eta: string;
  mode_of_transport: string | null;
  issuer_scac: string | null;
  paperless_inbond_participant: boolean;
  created_at: string;
  updated_at: string;
  bols: BOL[] | null;
}

export interface ACI {
  id: number;
  ams_no: string;
  ext_id: string;
  manifest_id: string;
  scac: string;
  vessel: string | null;
  voyage: string | null;
  status: AMStatus;
  vessel_country_code: string | null;
  port_of_discharge: string | null;
  eta: string;
  mode_of_transport: string | null;
  issuer_scac: string | null;
  paperless_inbond_participant: boolean;
  created_at: string;
  updated_at: string;
  bols: BOL[] | null;
}

export interface BOL {
  id: number;
  ext_id: string;
  ref_no: string;
  bl_indicator: string;
  issuer_scac: string | null;
  bl_no: string;

  hbl_no: string;
  mbl_no: string | null;
  second_notify_scac: string | null;
  status: AMStatus;
  place_of_receipt: string | null;
  port_of_loading: string | null;
  last_foreign_port: string;
  updated_at: string | null;
  created_at: string | null;
  containers: ShipmentContainer[];
}

export interface ContactAccountTotals {
  invoicesTotalAmount: number;
  billsTotalAmount: number;
  readyBillsTotalAmount: number;
  balance: number;
  readyBalance: number;
  availableCredit: number;
  availablePayableCredit: number;
}

export type DCNoteType = 'debit' | 'credit';
export type DCNoteStatus = 'draft' | 'open' | 'partially_paid' | 'pending_paid' | 'paid' | 'void';
export interface DCNote {
  id: number;
  ext_id: string;
  type: DCNoteType;
  contact: Contact | null;
  date: string | null;
  due_date: string | null;
  creator: UserInfoInterface | null;
  credit: number;
  debit: number;
  charge_items: ChargeItem[];
  documents: Document[] | null;
  shipment: Shipment | null;
  status: DCNoteStatus;
  code: string | null;
  void_at: string | null;
  payments: Payment[];
  pending: number;
  balance: number;
  reconciled: boolean | null;
  hbl: number | null;
  memo: string | null;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  block_by: UserInfoInterface | null;
  office: Office | null;
  currency_rate_source: 'setting' | 'override';
  currency_rate_override: Record<string, any> | null;
  booking: Booking | null;
  color: string | null;
  created_at: string | null;
  language: string | null;
  tax_codes?: TaxCode[];
  tax?: number;
}

export type SuggestionType =
  | 'create_bill'
  | 'create_invoice'
  | 'create_credit_note'
  | 'create_debit_note'
  | 'update_shipment'
  | 'update_shipment_memo'
  | 'create_shipment'
  | 'create_hbl'
  | 'update_document'
  | 'add_shipment_documents'
  | 'associate_shipment'
  | 'create_quotation'
  | 'reply_email'
  | 'handle_document';
export interface Suggestion {
  id: number;
  type: SuggestionType;
  document: number;
  payload: Record<string, any> | null;
  inbox_item: Record<string, any> | null;
  accepted_at: string | null;
  accepted_by: UserInfoInterface | null;
  accepted_params: Record<string, any> | null;
  accepted_results: Record<string, any> | null;
  rejected_at: string | null;
  rejected_by: UserInfoInterface | null;
}

export type InboxItemType = 'email' | 'openapi' | 'message';
export interface InboxItemUser {
  user: UserInfoInterface;
  read_at: string | null;
}
export interface InboxItemPayload {
  email?: Record<string, any>;
  context?: Record<string, any> | null;
}
export interface InboxItem {
  id: number;
  ext_id: string;
  type: InboxItemType;
  contact: Contact | null;
  from_email: string | null;
  to_email: string | null;
  payload: InboxItemPayload | null;
  created_at: string;
  suggestions: Suggestion[];
  documents: Document[];
  memos: Memo[];
  users: InboxItemUser[];
  read?: boolean;
  shipments?: Shipment[];
}
export interface InboxItemDetails extends InboxItem {
  shipments: Shipment[];
  quotations: Quotation[];
  hbls: (HBL & { shipment: number })[];
}

export interface Account {
  id: number;
  ext_id: string;
  name: string;
  number: string | null;
  bank_name: string | null;
  bank_address: string | null;
  bank_phone: string | null;
  currency: string;
  enabled: boolean;
  creator: UserInfoInterface | null;
  has_transaction?: boolean;
  balance?: number;
  office: Office | null;
  gl_code: GLCode | null;
  is_main: boolean | null;
}

export interface PebbleAction {
  id: string;
  type: string;
  field?: string;
  value?: string | number | boolean;
}
export interface PebbleCondition {
  type: string;
  args?: (string | { path: string })[];
  name: string;
}
export interface PebbleTrigger {
  name: string;
}
export interface Pebble {
  id: number;
  ext_id: string;
  condition: PebbleCondition[];
  actions: PebbleAction[];
  priority: number;
  name: string;
  triggers: PebbleTrigger[];
}

export interface GLCode {
  id: number;
  code: string;
  type: string;
  name: string;
  description: string | null;
  parent: GLCode | null;
  children?: GLCode[];
}
export interface GeneralLedger {
  id: number;
  date: string;
  source?: string | null;
  target?: string | null;
  ref_no?: string | null;
  description?: string | null;
  debit: number;
  credit: number;
  gl_code: GLCode;
  contact: Contact | null;
  account?: Account | null;
  currency: string | null;
  debit_in_currency: number;
  credit_in_currency: number;
  invoice?: Invoice | null;
  bill?: Bill | null;
  dc_note?: DCNote | null;
  transaction?: Transaction | null;
  payment?: Payment | null;
  journal_entry?: JournalEntry | null;
  shipment?: Shipment | null;
  hbl?: HBL | null;
  tx_id: string;
  reconciled: boolean | null;
  seq: number | null;
}
export interface JournalEntry {
  id: number;
  ext_id: string;
  issue_by: UserInfoInterface;
  block_by: UserInfoInterface | null;
  type: string;
  post_date: string;
  date_seq: number;
  remark: string | null;
  debit: number;
  credit: number;
  is_block: boolean;
  block_date: string | null;
  is_block_perm: boolean;
  general_ledgers: GeneralLedger[];
  office?: Office | null;
}
export interface JournalEntryChange {
  id: number;
  type: ActionTypes;
  payload: Action[] | null;
  user: UserInfoInterface | null;
  created_at: string;
}

export interface AMSChange extends BaseChanges {
  is_cbp?: boolean;
}

export interface Currency {
  id: number;
  name: string;
  is_default: boolean;
  office: Office | null;
  rounding: number | null;
}
export interface CurrencyRate {
  id: number;
  as_of_date: string | null;
  rate: number;
  from: Currency;
  to: Currency;
  office: Office | null;
  user: UserInfoInterface | null;

  inverse_exchange_rate: number;
}

export interface Office {
  id: number;
  code: string;
  name: string;
  timezone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
}

export interface Approval {
  id: number;
  ext_id: string;
  module: string;
  operator: UserInfoInterface | null;
  operated_at: string | null;
  approvers: UserInfoInterface[];
  submitted_by: UserInfoInterface | null;
  status: 'pending' | 'approved' | 'rejected' | 'canceled';
  remark: string | null;
  created_at: string;
  hbl?: HBL | null;
}

export enum DefaultValueModel {
  ocean_import = 'ocean_import',
  ocean_export = 'ocean_export',
  air_import = 'air_import',
  air_export = 'air_export',
  misc = 'misc',
}
export interface ShipmentApproval extends Shipment {
  approvals: Approval[];
}
export interface StatementApproval extends Statement {
  approvals: Approval[];
}
export interface QuotationApproval extends Quotation {
  approvals: Approval[];
}
export interface QtoShipmentApproval extends QtoShipment {
  approvals: Approval[];
}

export interface Notification {
  id: number;
  entity_type: 'approval' | 'statement' | 'inbox_item';
  entity_id: number;
  user: UserInfoInterface | null;
  created_at: string;
  message: string;
  approval?: Approval;
  statement?: Statement;
  read: boolean | null;
  read_at: string | null;
}

export interface ContainerSizeType {
  id: number;
  ext_id: string;
  code: string;
  description: string | null;
  is_active: boolean | null;
  ams_type: string | null;
  teu: number;
  group_code: string | null;
}

interface ColorSetting {
  color: string;
  label: string | null;
}

export interface ColorType {
  id: number;
  ext_id: string;
  type: 'mbl' | 'hbl' | 'ar_ap';
  settings: ColorSetting[];
}

export interface TaxCode {
  id: number;
  ext_id: string;
  code: string;
  description: string | null;
  rate: number;
  received_gl_code: GLCode | null;
  paid_gl_code: GLCode | null;
  payable_gl_code: GLCode | null;
  type: string | null;
  office: Office | null;
}

export interface Quotation {
  id: number;
  ext_id: string;
  status: string;
  code: string;
  title: string;
  quotation_type: string;
  ref_no: string;
  customer?: Contact | null;
  sales?: UserInfoInterface | null;
  validity_day?: number;
  issue_date?: string | null;
  expire_date?: string | null;
  created_at: string;
  description: string;
  shipment_type: ShipmentType;
  charge_groups: QuotationChargeGroup[];
  routes: Route[];
  currency: string | null;
  approved_at: string | null;
  approved_by: UserInfoInterface | null;
  approvals?: Approval[];
}
export interface QuotationChargeGroup {
  id: number;
  ext_id: string;
  type: string | null;
  charge_items: ChargeItem[];
  routes: Route[];
}
export interface Route {
  id: number;
  ext_id: string;
  place_of_receipt: string | null;
  port_of_loading: string | null;
  port_of_discharge: string | null;
  port_of_transit: string | null;
  place_of_delivery: string | null;
}
export interface TermTemplates {
  id: number;
  ext_id: string;
  title: string | null;
  terms: string | null;
  office: Office | null;
}
export interface QtoShipment {
  id: number;
  ext_id: string;
  status: string;
  code: string;
  customer?: Contact | null;
  sales?: UserInfoInterface | null;
  operator?: UserInfoInterface | null;
  created_at: string;
  remark: string;
  shipment_type: ShipmentType;
  charge_groups: QuotationChargeGroup[];
  approved_at: string | null;
  approved_by: UserInfoInterface | null;
  approvals?: Approval[];
}

export interface AIContact {
  name: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phone?: string;
  email?: string;
}
