import { DvaModelBuilder, actionCreatorFactory } from 'dva-model-creator';
import { ChargeItemCode, GLCode } from '@/common/type';
import { getChargeItemCodes, getGLCodes } from '@/services/accounting';
import { RequestData } from '@ant-design/pro-table';

export interface AccountingModel {
  chargeItemCodes: ChargeItemCode[] | null;
  glCodes: GLCode[] | null;
}

const actionCreator = actionCreatorFactory('accounting');
const fetchChargeItemCodes = actionCreator('fetchChargeItemCodes');
const fetchGLCodes = actionCreator('fetchGLCodes');
const saveData = actionCreator<{
  chargeItemCodes?: ChargeItemCode[];
  glCodes?: GLCode[];
}>('saveData');

const initState: AccountingModel = {
  chargeItemCodes: null,
  glCodes: null,
};

export default new DvaModelBuilder(initState, 'accounting')
  .takeEvery(fetchChargeItemCodes, function*(_, { call, put }) {
    const response: RequestData<ChargeItemCode> | null = yield call(getChargeItemCodes);
    if (response && response.data && response.success) {
      yield put(saveData({ chargeItemCodes: response.data }));
    }
  })
  .takeEvery(fetchGLCodes, function*(_, { call, put }) {
    const response: GLCode[] | null = yield call(getGLCodes, { _limit: -1 });
    if (response) {
      yield put(saveData({ glCodes: response }));
    }
  })
  .case(saveData, (state, data) => ({
    ...state,
    ...data,
  }))
  .build();

export const actions = {
  fetchChargeItemCodes,
  fetchGLCodes,
  saveData,
};
