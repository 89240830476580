import shipment from './shipment';
// import common from './common';
export default {
  'bol.field.bl_indicator': 'Bl Indicator',
  'bol.field.ref_no': 'Ref No',
  'bol.field.issuer_scac': 'Issuer SCAC',
  'bol.field.hbl_no': shipment['shipment.field.hbl_no'],
  'bol.field.package_unit': 'Package Unit',
  'bol.field.mbl_no': shipment['shipment.field.mbl_no'],
  'bol.field.consignee': shipment['shipment.field.consignee'],
  'bol.field.shipper': shipment['shipment.field.shipper'],
  'bol.field.notify': shipment['shipment.field.notify'],
  'bol.field.place_of_delivery': shipment['shipment.field.place_of_delivery'],
  'bol.field.place_of_delivery_eta': shipment['shipment.field.place_of_delivery_eta'],
  'bol.field.status': 'Status',
  'bol.field.place_of_receipt': 'Place Of Receipt',
  'bol.field.last_foreign_port': 'Last Foreign Port',
  'bol.field.second_notify_scac': 'Second Notify SCAC',
  'bol.field.created_at': 'Created At',
  'bol.field.updated_at': 'Updated At',
  'bol.field.port_of_loading': 'POL',
  'bol.tooltip.already_attach': 'Already Attach',
  'bol.saved.success': 'Successfully Saved BOL',
  'bol.updated.success': 'Successfully Update BOL',
  'bol.deleted.success': 'Successfully Deleted BOL',
  'bol.title.create': 'Create Billing Of Lading Summary',
  'bol.title.container': 'Container',
  'bol.title.hbl': 'HBL',
  'bol.title.basic': 'Basic',
  'bol.title.container.creatorButton': 'Add Container',
  'bol.list_title.list': 'BOL',
  'bol.tooltip.already_submitted': 'Bill Of Lading already submitted',
};
