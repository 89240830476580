export default {
  'shipment_changes.create': '{user} created shipment draft',
  'shipment_changes.update': '{user} updated shipment info {changes}',
  'shipment_changes.delete_hbl': '{user} deleted HBL {hbl_no}',
  'shipment_changes.submit': '{user} submitted shipment',
  'shipment_changes.archive': '{user} archived shipment',
  'shipment_changes.unarchive': '{user} unarchived shipment',
  'shipment_changes.add_todo': '{user} added {todo} to-do',
  'shipment_changes.update_todo': '{user} updated {todo} to-do',
  'shipment_changes.complete_todo': '{user} completed {todo} to-do',
  'shipment_changes.delete_todo': '{user} deleted {todo} to-do',
  'shipment_changes.email_todo': '{user} emailed {todo}',
  'shipment_changes.add_document': '{user} uploaded {document_type} document {document}',
  'shipment_changes.delete_document': '{user} deleted {document_type} document {document}',
  'shipment_changes.send_document': 'Sent {document_type} to {emails}',
  'shipment_changes.assign': '{user} assigned shipment',
  'shipment_changes.add_charge_item': 'Added item {code} for {contact}',
  'shipment_changes.update_charge_item': 'Updated item {code} for {contact}',
  'shipment_changes.remove_charge_item': 'Removed item {code}',
  'shipment_changes.approve_charge_item': 'Approved item {code} for {contact}',
  'shipment_changes.create_invoice': 'Created invoice {code} for {contact}',
  'shipment_changes.submit_incoming_transaction':
    'Added incoming transaction {code} from {contact}',
  'shipment_changes.confirm_incoming_transaction': 'Confirmed deposit {code} from {contact}',
  'shipment_changes.submit_outgoing_transaction': 'Added payment {code} to {contact}',
  'shipment_changes.duplicate': '{user} duplicated shipment{original}',
  'shipment_changes.void_transaction': 'Voided {transaction_type} {code} for {contact}',
  'shipment_changes.void_invoice': 'Voided invoice {code} for {contact}',
  'shipment_changes.create_bill': 'Created bill for {contact}',
  'shipment_changes.void_bill': 'Voided bill for {contact}',
  'shipment_changes.reconcile_invoice': 'Reconciled invoice {code} for {contact}',
  'shipment_changes.reconcile_bill': 'Reconciled bill for {contact}',
  'shipment_changes.add_memo': 'Added memo "{subject}"',
  'shipment_changes.update_memo': 'Updated memo "{subject}"',
  'shipment_changes.delete_memo': 'Deleted memo "{subject}"',
  'shipment_changes.submit_approved': 'Shipment submission approved',
  'shipment_changes.submit_rejected': 'Shipment submission rejected',
  'shipment_changes.submit_canceled': 'Shipment submission canceled',
  'shipment_changes.submit_release_request': 'Submitted shipment release request',
  'shipment_changes.release_approved': 'Shipment release approved',
  'shipment_changes.release_rejected': 'Shipment release rejected',
  'shipment_changes.release_canceled': 'Shipment release canceled',
  'shipment_changes.push_edi': 'Pushed EDI',
};
