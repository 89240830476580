export default {
  'approval.module': 'Module',
  'approval.modules.payment_plan': 'Payment Plan',
  'approval.modules.shipment_submit': 'Shipment Submit',
  'approval.modules.shipment_release': 'Shipment Release',
  'approval.modules.statement_payment': 'Payment Statement',
  'approval.modules.statement_receivable': 'Income Statement',
  'approval.modules.quotation_approval': 'Quotation Approval',
  'approval.modules.qtoshipment_approval': 'Shipment Request Approval',
  'approval.field.approvers': 'Approvers',
  'approval.field.approval_status': 'Approval Status',
  'approval.field.submitted_by': 'Submitted By',
  'approval.field.submitted_at': 'Submitted At',
  'approval.status.pending': 'Pending',
  'approval.status.approved': 'Approved',
  'approval.status.rejected': 'Rejected',
  'approval.status.canceled': 'Canceled',
  'approval.action.approve': 'Approve',
  'approval.approve.confirm': 'Are you sure to approve this request?',
  'approval.action.reject': 'Reject',
  'approval.reject.confirm': 'Are you sure to reject this request?',
  'approval.action.cancel': 'Cancel',
  'approval.cancel.confirm': 'Are you sure to cancel this request?',
  'approval.approve.success': 'Approved request successfully',
  'approval.reject.success': 'Rejected request successfully',
  'approval.cancel.success': 'Canceled request successfully',
  'approval.approved_by': 'Approved By',
};
