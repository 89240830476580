export default {
  'accounting.fapiao.list_title.list': 'Fapiao List',
  'accounting.fapiao.add.title': 'Add Fapiao',
  'accounting.fapiao.update.success': 'Successfully updated Fapiao',
  'accounting.fapiao.field.code': 'Code',
  'accounting.fapiao.field.number': 'Number',
  'accounting.fapiao.field.contact': 'Contact',
  'accounting.fapiao.field.amount': 'Amount',
  'accounting.fapiao.field.tax': 'Tax',
  'accounting.fapiao.field.statement': 'Statement',
  'accounting.fapiao.field.issue_date': 'Issue Date',
  'accounting.fapiao.field.verification_code': 'Verification Code',
  'accounting.fapiao.field.remark': 'Remark',
  'accounting.fapiao.field.creator': 'Creator',
  'accounting.fapiao.field.issue_date.required': 'Please select issue date',
  'accounting.fapiao.field.amount.required': 'Please input amount',
  'accounting.fapiao.field.document': 'Documents',
  'accounting.fapiao.field.request_invoicing_by': 'Request Invoicing By',
  'accounting.fapiao.field.list': 'Fapiaos',
  'accounting.fapiao.create.success': 'Successfully Create Fapiao',
  'accounting.fapiao.status.requested': 'Requested Fapiao',
  'accounting.fapiao.status.added': 'Added Fapiao',
  'accounting.fapiao.field.fapiao_status': 'Fapiao Status',
};
