export default {
  'app.login.userName': 'Username',
  'app.login.placeholder.email': 'Email address',
  'app.login.placeholder.password': 'Password',
  'app.login.display-name': 'Display Name',
  'app.login.email': 'Email Address',
  'app.login.password': 'Password',
  'app.login.message-invalid-credentials': 'Invalid email or password!',
  'app.login.message-invalid-verification-code': 'Invalid verification code!',
  'app.login.message-session-expired': 'Your session has expired. Please login again.',
  'app.login.remember-me': 'Remember Me',
  'app.login.forgot-password': 'Forgot Password',
  'app.login.signup': 'Sign Up',
  'app.login.login': 'Continue',
  'app.forgot-password.title': 'Forget Password',
  'app.forgot-password.success': 'A password reset link has been sent to your email.',
  'app.reset-password.title': 'Reset Password',
  'app.reset-password.placeholder.confirm': 'Confirm Password',
  'app.reset-password.reset': 'Reset Password',
  'app.reset-password.success':
    'Your password has been successfully reset. Please return to the login page to sign in.',
  'app.back-to-login': 'Back to Login',
  'app.register.already-have-account': 'Already have an account?',
  'app.login.dont-have-account': "Don't have an account?",
  'validation.email.required': 'Please enter your email address',
  'validation.email.wrong-format': 'Invalid email address',
  'validation.display-name.required': 'Please enter your display name',
  'validation.password.required': 'Please create a password for your account',
  'validation.confirm-password.match': 'The two passwords do not match',
  'signup.error.email_already_exists':
    'The email address is already associated with an existing account.',
  'app.login.sign-in-to-your-account': 'Sign in to your account',
  'logout.success': 'You have been logged out successfully.',
};
