export default {
  'workflow.create': 'Create New Workflow',
  'workflow.update': 'Update Workflow',
  'workflow.field.name': 'Name',
  'workflow.field.updated_at': 'Last Updated At',
  'workflow.list_title': 'Workflows',
  'workflow.create.success': 'Workflow created successfully',
  'workflow.update.success': 'Workflow updated successfully',
  'workflow.delete.success': 'Workflow deleted successfully',
  'workflow.delete.confirm.title': 'Are you sure you want to delete this workflow?',
  'workflow.delete.confirm.content': 'This action cannot be undone.',
  'workflow.title.basic_information': 'Basic Information',
  'workflow.title.conditions': 'Conditions',
  'workflow.title.actions': 'Actions',
  'workflow.trigger.option.on_shipment_create': 'On Shipment Create',
  'workflow.trigger.option.on_shipment_update': 'On Shipment Update',
  'workflow.trigger.placeholder': 'Add a Trigger',
  'workflow.condition.placeholder': 'Add a Condition',
  'workflow.action.placeholder': 'Add an Action',
  'workflow.condition.option.pol': 'Port of Loading is...',
  'workflow.condition.option.pod': 'Port of Discharge is...',
  'workflow.condition.option.operator': 'MBL Operator is...',
  'workflow.action.option.update_shipment': 'Update Shipment...',
};
