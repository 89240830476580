import { DvaModelBuilder, actionCreatorFactory } from 'dva-model-creator';
import { LayoutModel } from '@/common/type';

const actionCreator = actionCreatorFactory('layout');
const setLayout = actionCreator<Partial<LayoutModel>>('setLayout');

const initState: LayoutModel = {
  isMobile: false,
};

export default new DvaModelBuilder(initState, 'layout')
  .case(setLayout, (state, data) => ({
    ...state,
    ...data,
  }))
  .build();

export const actions = {
  setLayout,
};
