export default {
  'dashboard.draftShipments.empty': 'All assigned!',
  'dashboard.draftShipments.title': 'My drafts',
  'dashboard.inbox.title': 'All Items',
  'dashboard.inbox.all': 'All',
  'dashboard.inbox.read': 'Read',
  'dashboard.inbox.unread': 'Unread',
  'dashboard.unassigned_shipments.assign.confirm':
    'Are you sure you want to assign these shipments to yourself?',
  'dashboard.unassigned_shipments.title': 'The following shipments needs to be assigned:',
  'dashboard.unassigned_shipments.assign.success': 'Successfully assigned { length } shipment',
  'dashboard.inbox.markAllRead': 'Mark All Read',
  'dashboard.total_profit': 'TOTAL PROFIT',
  'dashboard.total_volume': 'TOTAL VOLUME',
  'dashboard.active_customer': 'NO. OF ACTIVE CUSTOMER',
};
