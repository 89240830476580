import { get } from 'lodash';
import moment from 'moment';

export function renderLedgerLink(record: any) {
  let entityType;
  let entity;
  let text = record.ref_no;
  if (record.journal_entry) {
    entityType = 'journal_entry';
    entity = record.journal_entry;
  } else {
    for (const key of ['invoice', 'bill', 'dc_note', 'transaction', 'shipment']) {
      if (
        text &&
        record[key] &&
        record[key][key === 'shipment' ? 'shipment_number' : 'code'] === text
      ) {
        entityType = key;
        entity = record[key];
        break;
      }
      if (!text && record[key] && !record[key].code) {
        if (
          (key === 'bill' && ['expense', 'payable'].includes(record.target || '')) ||
          (key === 'transaction' && ['cash', 'transaction'].includes(record.target || ''))
        ) {
          entityType = key;
          entity = record[key];
          break;
        }
      }
    }
  }
  if (entityType && entity) {
    let path = '';
    if (entityType === 'dc_note') {
      path = `/${entity.type}_notes`;
    } else if (entityType === 'invoice') {
      path = `/invoices`;
    } else if (entityType === 'bill') {
      path = `/bills`;
    } else if (entityType === 'transaction') {
      path = `/transactions/${entity.direction === 'incoming' ? 'incoming_' : ''}payments`;
    } else if (entityType === 'journal_entry') {
      path = `/journals`;
      if (!text) {
        text = `${entity.post_date}: ${entity.date_seq}`;
      }
    } else if (entityType === 'shipment' && entity.type) {
      return {
        text: text || '-',
        href: `/shipments/${entity.type}/${entity.ext_id}`,
      };
    }
    return {
      text: text || '-',
      href: `/accounting${path}/${entity.ext_id}`,
    };
  }
  return {
    text: text || '-',
  };
}

export function lbsToKg(pounds) {
  return Number.parseFloat(pounds / 2.20462);
}

export function cftToCbm(cubicFeet) {
  return Number.parseFloat(cubicFeet / 35.3147);
}

export function getMBLFromWebsite(mbl_no: string) {
  if (!mbl_no) {
    return null;
  }
  mbl_no = mbl_no.toUpperCase();
  mbl_no = mbl_no.replace(/[^A-Z0-9]/g, '');
  const scac = mbl_no.substring(0, 4);
  const bl_no = mbl_no.substring(4);
  const mapping = {
    HLCU: `https://www.hapag-lloyd.com/en/online-business/track/track-by-booking-solution.html?blno=${mbl_no}`,
    HDMU: `https://www.hmm21.com/e-service/general/DashBoard.do`,
    CMDU: `https://www.cma-cgm.com/`,
    WHLC: `https://www.wanhai.com/views/Main.xhtml`,
    MAEU: `https://www.maersk.com/tracking/#tracking/${bl_no}`,
    COSU: `https://elines.coscoshipping.com/ebusiness/cargoTracking?trackingType=BOOKING&number=${bl_no}`,
    YMJA: `https://www.yangming.com/e-service/track_trace/track_trace_cargo_tracking.aspx`,
    ONEY: `https://ecomm.one-line.com/one-ecom/manage-shipment/cargo-tracking?trakNoParam=${bl_no}`,
    MEDU: `https://www.msc.com/`,
    EGLV: `https://ct.shipmentlink.com/servlet/TDB1_CargoTracking.do`,
    OOLU: `https://www.oocl.com/eng/Pages/default.aspx`,
  };
  return mapping?.[scac];
}

export function getLastClosingDate(monthlyClosingSetting: Record<string, any> | null | undefined) {
  let closingDate = get(monthlyClosingSetting, 'day');
  if (closingDate) {
    closingDate = moment()
      .subtract(1, 'month')
      .date(closingDate);
    closingDate = closingDate.format('YYYY-MM-DD');
  }
  return closingDate;
}

export function buildQueryParams(params: any) {
  const query = new URLSearchParams();
  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      query.append(key, params[key]);
    }
  }
  return query.toString();
}

export const ExcelDefaultTheadCellStyle = {
  background: 'FF31437c',
  color: 'FFFFFFFF',
  fontSize: 10,
  fontName: 'Helvetica',
  bold: true,
  border: true,
  v: 'center',
  wrapText: true,
};

export const ExcelDefaultTbodyCellStyle = {
  wrapText: true,
  fontName: 'Helvetica',
  fontSize: 10,
};

export function slugifyCompanyName(name: string) {
  return name
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}
