import { AntdProRequest } from '@/common/antdProTyping';
import { Contact, ContactTag, Email } from '@/common/type';
import request from '@/utils/request';
import { SortOrder } from 'antd/lib/table/interface';
import { keys, map } from 'lodash';
import { getAccountBalance } from '@/services/accounting';

export const getContacts: AntdProRequest<Contact> = (params = {}, sort?, filter?) => {
  const { pageSize, current, where, ...rest } = params;
  const _sort = sort
    ? map(sort, (order, key) => {
        const field = key.replace(',', '.');
        const orderDir = order && order.startsWith('desc') ? 'DESC' : 'ASC';
        return `${field}:${orderDir}`;
      })
    : [];
  if (!keys(sort).includes('updated_at')) {
    _sort.push('updated_at:DESC');
  }
  return request(where ? `/contacts?${where}` : `/contacts`, {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort: _sort.join(','),
    },
  });
};

// using fuzzy search
export const getContactsByKeyword = (params: {
  pageSize?: number;
  current?: number;
  keyword: string;
}) => {
  const { pageSize = 10, current = 1, keyword } = params;
  return request(`/contacts/fuzzy`, {
    params: {
      keyword,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
    },
  });
};

export const getContactChanges = (id: string) => {
  return request(`/contacts/${id}/changes`);
};

export const getContactTags = () => {
  return request<{ data?: ContactTag[] }>(`/contact-tags`, {
    params: {
      _limit: -1,
    },
  });
};

export const getContactDetails = async (id: string) => {
  const contact = await request(`/contacts/${id}`);
  if (contact && contact.id) {
    const accountTotalsResponse = await getAccountBalance(contact.id);
    if (accountTotalsResponse && accountTotalsResponse.success) {
      contact.accounts = accountTotalsResponse.data;
    }
  }
  return contact;
};

export const postContact = (data: Record<string, any>) => {
  return request('/contacts', {
    method: 'POST',
    data,
  });
};

export const disableContact = (id: string) => {
  return request(`/contacts/${id}/disable`, {
    method: 'POST',
  });
};
export const batchDisableContact = (ids: string[]) => {
  return request('/contacts/disable', {
    method: 'POST',
    data: { ext_ids: ids },
  });
};
export const batchEnableContact = (ids: string[]) => {
  return request('/contacts/enable', {
    method: 'POST',
    data: { ext_ids: ids },
  });
};
export const batchDeleteContact = (ids: string[]) => {
  return request('/contacts/delete', {
    method: 'POST',
    data: { ext_ids: ids },
  });
};

export const enableContact = (id: string) => {
  return request(`/contacts/${id}/enable`, {
    method: 'POST',
  });
};

export const updateContact = (id: string, data: { [keys: string]: string }) => {
  return request(`/contacts/${id}`, {
    method: 'PUT',
    data,
  });
};

export const deleteContact = (id: string) => {
  return request(`/contacts/${id}`, {
    method: 'DELETE',
  });
};

export const getContactTypes = () => {
  return request('/contact-types', {
    errorHandler: async error => {
      const { response } = error;
      return response.json();
    },
  });
};

export const getContactOpenInvoicesBills = (
  extId: string,
  params: Record<string, any> = {},
  sort?: Record<string, SortOrder> | undefined,
) => {
  return request(`/contacts/${extId}/pending_invoices_bills`, {
    params: {
      ...params,
      _sort: sort
        ? map(sort, (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`)
        : undefined,
    },
  });
};

// support add multiple documents
export const addDocuments = (id: string, data: { [keys: string]: any }) => {
  const { files, ...rest } = data;
  const formData = new FormData();
  if (files && files.length) {
    files.forEach((file: File) => {
      formData.append('files.files', file);
    });
  }
  formData.append('data', JSON.stringify(rest));
  return request(`/contacts/${id}/documents`, {
    method: 'POST',
    body: formData,
  });
};

export const getDocuments = (contactId: string, params?: Record<string, string | number>) => {
  return request(`/contacts/${contactId}/documents`, { params });
};

export const deleteDocument = (id: number) => {
  return request(`/documents/${id}`, {
    method: 'DELETE',
  });
};

export const updateDocument = (params: { id: string; data: any }) => {
  const { id, ...data } = params;
  return request(`/documents/${id}`, {
    method: 'PUT',
    data,
  });
};

export const getMemos = (params: { contact?: string }) => {
  const { contact } = params;
  if (!contact) {
    return [];
  }
  return request(`/contacts/${contact}/memos`);
};

export const addMemo = (params: { subject: string; content?: string; contact?: string }) => {
  const { contact, ...data } = params;
  if (!contact) {
    return;
  }
  return request(`/contacts/${contact}/memos`, {
    method: 'POST',
    data,
  });
};

export const updateMemo = (params: { id: string; data: any }) => {
  const { id, ...data } = params;
  return request(`/memos/${id}`, {
    method: 'PUT',
    data,
  });
};
export const deleteMemo = (params: { id: number; contact?: string }) => {
  const { contact, id } = params;
  if (!contact) {
    return;
  }
  return request(`/contacts/${contact}/memos/${id}`, {
    method: 'DELETE',
  });
};

export const mergeContacts = (data: Record<string, any>) => {
  return request('/contacts/merge', {
    method: 'POST',
    data,
  });
};

export const tagContacts = (data: Record<string, any>) => {
  return request('/contacts/tags', {
    method: 'POST',
    data,
  });
};

export const switchToRegular = (extIds: string[]) => {
  return request('/contacts/regular', {
    method: 'POST',
    data: {
      ext_ids: extIds,
    },
  });
};

export const batchContacts = (data: Record<string, any>) => {
  return request('/contacts/batch', {
    method: 'POST',
    data,
  });
};

export const linkEDIContact = (data: Record<string, any>) => {
  return request('/contacts/link_edi', {
    method: 'POST',
    data,
  });
};

export const getEmails: AntdProRequest<Email> = (params = {}, sort?, filter?) => {
  const { pageSize, current, ...rest } = params;
  const options: Record<string, any> = {
    params: {
      ...rest,
      _limit: pageSize,
      _start: pageSize && current ? pageSize * (current - 1) : undefined,
      _sort:
        sort && keys(sort).length
          ? map(
              sort,
              (order, key) => `${key}:${order && order.startsWith('desc') ? 'DESC' : 'ASC'}`,
            )
          : 'created_at:DESC',
    },
  };
  return request('/emails', options);
};
