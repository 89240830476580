import shipment from './shipment';
import common from './common';
export default {
  'booking.field.booking_no': 'Booking No.',
  'booking.field.hbl_no': shipment['shipment.field.hbl_no'],
  'booking.field.final_destination': shipment['shipment.field.final_destination'],
  'booking.field.type': shipment['shipment.field.type'],
  'booking.type.air_export': shipment['shipment.type.air_export'],
  'booking.type.ocean_export': shipment['shipment.type.ocean_export'],

  'booking.field.mbl_no': shipment['shipment.field.mbl_no'],
  'booking.field.ref_no': 'Reference No.',
  'booking.field.itn_no': 'ITN No.',
  'booking.field.sub_agent_awb': 'Sub Agent AWB',
  'booking.field.booking_date': 'Booking Date',
  'booking.field.carrier': shipment['shipment.field.carrier'],
  'booking.field.trucker': shipment['shipment.field.trucker'],
  'booking.field.shipper': shipment['shipment.field.shipper'],
  'booking.field.agent': shipment['shipment.field.agent'],
  'booking.field.customer': shipment['shipment.field.customer'],
  'booking.field.bill_to': shipment['shipment.field.bill_to'],
  'booking.field.flight': shipment['shipment.field.flight_no'],
  'booking.field.cargo_pickup': 'Cargo Pickup',
  'booking.field.final_eta': shipment['shipment.field.final_eta'],
  'booking.field.cargo_type': shipment['shipment.field.cargo_type'],
  'booking.field.sales_type': shipment['shipment.field.sales_type'],
  'booking.field.consignee': shipment['shipment.field.consignee'],
  'booking.field.notify': shipment['shipment.field.notify'],
  'booking.field.coloader': shipment['shipment.field.coloader'],
  'booking.field.incoterms': shipment['shipment.field.incoterms'],
  'booking.field.port_of_loading': common['common.field.port_of_loading'],
  'booking.field.port_of_discharge': common['common.field.port_of_discharge'],
  'booking.field.etd': shipment['shipment.field.etd'],
  'booking.field.eta': shipment['shipment.field.eta'],
  'booking.field.operator': shipment['shipment.field.operator'],
  'booking.field.sales': shipment['shipment.field.sales'],
  'booking.field.air_insurance': shipment['shipment.field.air_insurance'],

  'booking.field.po_no': 'P.O Number',
  'booking.field.wh_cut_off_at': 'W/H Cut-Off Date',
  'booking.field.vessel': shipment['shipment.field.vessel'],
  'booking.field.voyage': shipment['shipment.field.voyage'],
  'booking.field.doc_cut_off_at': 'Doc Cut-Off Date',
  'booking.field.port_cut_off_at': 'Port Cut-Off Date',
  'booking.field.vgm_cut_off_at': 'VGM Cut-Off Date',
  'booking.field.rail_cut_off_at': 'Rail Cut-Off Date',
  'booking.field.early_return_at': 'Early Return Date',
  'booking.field.on_board_date': 'On Board Date',
  'booking.field.mark': 'Mark',
  'booking.field.description': 'Description',
  'booking.field.remarks': 'Booking Remarks',
  'booking.field.freight_payment': shipment['shipment.field.freight_payment'],
  'booking.field.selling_freight': 'Selling Freight',
  'booking.create.success': 'Booking created successfully',
  'booking.update.success': 'Booking updated successfully',
  'booking.delete.success': 'Booking deleted successfully',
  'booking.create_shipment.success': 'Booking create shipment successfully',
  'booking.duplicate.success': 'Booking duplicated successfully',
  'booking.ship_type': '{ship_type, select,normal {NORMAL} s_w{S_W} t_s{T_S} Other{Other}}',
  'booking.booking_cost': 'Booking Cost',
  'booking.booking_profit': 'Booking Profit',
  'booking.booking_revenue': 'Booking Revenue',
  'booking.create_shipment': 'Create Shipment',
  'booking.view_shipment': 'View Shipment',

  'booking.tab.info': 'Info',
  'booking.tab.accounting': 'Accounting',
  'booking.tab.documents': 'Documents',
  'booking.tab.status': 'Status',
  'booking.information': 'Booking Information',
  'booking.list.title': 'Booking List',
  'booking.list.edit_booking': 'Edit Booking',
};
