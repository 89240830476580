export default {
  'quotation.create': 'Create Quotation',
  'quotation.created.success': 'Successfully Created Quotation',
  'quotation.detail': 'Quotation Detail',
  'quotation.field.title': 'Title',
  'quotation.field.shipment_type': 'Shipment Type',
  'quotation.field.code': 'Code',
  'quotation.field.type': 'Type',
  'quotation.field.customer': 'Customer',
  'quotation.field.description': 'Description',
  'quotation.field.pol': 'POL',
  'quotation.field.pod': 'POD',
  'quotation.field.place_of_delivery': 'Place Of Delivery',
  'quotation.field.status': 'Status',
  'quotation.field.issue_date': 'Issue Date',
  'quotation.field.expire_date': 'Expire Date',
  'quotation.field.created_at': 'Created At',
  'quotation.field.terms_and_conditions': 'Terms and Conditions',
  'quotation.total_price': 'Total Price',
  'quotation.total_cost': 'Total Cost',
  'quotation.profit': 'Profit',
  'quotation.list.title': 'Quotation List',
  'quotation.deleted.success': 'Quotation deleted successfully',
  'quotation.deleted.title': 'Are you sure delete this quotation?',
  'quotation.deleted.cannot_undo': 'This action cannot be undone.',
  'quotation.deleted.cancel': 'Deletion cancelled',
  'quotation.report': 'Print/Email Quotation',
  'quotation.charge_item_group.freight_charge': 'Freight Charges',
  'quotation.charge_item_group.destination_charge': 'Destination Charges',
  'quotation.title.basic_information': 'Basic Information',
  'quotation.title.routes': 'Routes',
  'quotation.title.charge_items': 'Charge Items',
  'quotation.submit.confirm': 'Are you sure to submit this quotation?',
  'quotation.publish.confirm': 'Are you sure to publish this quotation?',
  'quotation.unsubmit.confirm': 'Are you sure to unsubmit this quotation?',
  'quotation.unpublish.confirm': 'Are you sure to unpublish this quotation?',
  'quotation.create_qtoshipment': 'Create Shipment Request',
  'qtoshipment.create': 'Create Shipment Request',
  'qtoshipment.detail': 'Shipment Request Detail',
  'quotation.title.costs': 'Costs',
};
