export default {
  'Account not found': 'Account not found',
  'Account has associated transactions': 'Account has associated transactions',
  'bols must be Array': 'bols must be Array',
  'Invalid module': 'Invalid module',
  'Approval feature is not enabled': 'Approval feature is not enabled',
  'Missing module param': 'Missing module param',
  'Invalid module or missing module param': 'Invalid module or missing module param',
  'You are not allowed to approve this': 'You are not allowed to approve this',
  'No approval rule found': 'No approval rule found',
  'Bill not found': 'Bill not found',
  'Missing contact id': 'Missing contact id',
  'Payment is not pending': 'Payment is not pending',
  'Bill is void': 'Bill is void',
  'Booking not found': 'Booking not found',
  'Invalid shipment status': 'Invalid shipment status',
  'Charge item template not found': 'Charge item template not found',
  'Template not found': 'Template not found',
  'Contact not found': 'Contact not found',
  'Exiting contact not found': 'Exiting contact not found',
  'Contact was deleted': 'Contact was deleted',
  'Invalid contact': 'Invalid contact',
  'Cannot delete the contact with existing invoices!':
    'Cannot delete the contact with existing invoices!',
  'Cannot delete the contact with existing bills!':
    'Cannot delete the contact with existing bills!',
  'You do not have permission to disable contacts':
    'You do not have permission to disable contacts',
  'You do not have permission to enable contacts': 'You do not have permission to enable contacts',
  'Missing required params': 'Missing required params',
  'Memo not found': 'Memo not found',
  'You do not have permission': 'You do not have permission',
  'Error while processing payments': 'Error while processing payments',
  'Ledger is not linked to any transaction or d/c note':
    'Ledger is not linked to any transaction or d/c note',
  'Ledger does not have enough amount to cover payments':
    'Ledger does not have enough amount to cover payments',
  'Error while processing ledgers': 'Error while processing ledgers',
  'Missing required parameter': 'Missing required parameter',
  'Missing currency': 'Missing currency',
  'Local currency not found': 'Local currency not found',
  'Target currency not found': 'Target currency not found',
  'Currency rate not found': 'Currency rate not found',
  'D/C Note not found': 'D/C Note not found',
  'Missing params': 'Missing params',
  'Missing required parameters': 'Missing required parameters',
  'D/C Note is not in draft': 'D/C Note is not in draft',
  'D/C Note is not in open status': 'D/C Note is not in open status',
  'D/C Note is already voided': 'D/C Note is already voided',
  'Cannot void draft D/C Note': 'Cannot void draft D/C Note',
  'Cannot void paid D/C Note': 'Cannot void paid D/C Note',
  'Error while making payment for D/C Note': 'Error while making payment for D/C Note',
  'Error while confirming payment for D/C Note': 'Error while confirming payment for D/C Note',
  'D/C Note is void': 'D/C Note is void',
  'Error while voiding payment for D/C Note': 'Error while voiding payment for D/C Note',
  'Multiple available ledgers found': 'Multiple available ledgers found',
  'Document not found': 'Document not found',
  'No file or more than one file': 'No file or more than one file',
  'Missing parameter': 'Missing parameter',
  'Documents not found': 'Documents not found',
  'Shipment not found': 'Shipment not found',
  'Missing shop': 'Missing shop',
  'No HBL can be found': 'No HBL can be found',
  'No MBL can be found': 'No MBL can be found',
  'Only one HBL is allowed': 'Only one HBL is allowed',
  'User not found': 'User not found',
  'email and password are required': 'email and password are required',
  'not supported': 'not supported',
  'This email is already associated with an account.':
    'This email is already associated with an account.',
  'No User ID': 'No User ID',
  'Missing account information': 'Missing account information',
  'Missing account GL code': 'Missing account GL code',
  'Error creating general ledger for payment': 'Error creating general ledger for payment',
  'Error creating general ledger for payment: transaction not found':
    'Error creating general ledger for payment: transaction not found',
  'Error making payment: GL code not found': 'Error making payment: GL code not found',
  'Error making payment: invalid GL code type': 'Error making payment: invalid GL code type',
  'Error voiding general ledger: transaction not found':
    'Error voiding general ledger: transaction not found',
  'Error creating general ledger for credit: missing transaction':
    'Error creating general ledger for credit: missing transaction',
  'Error creating general ledger for credit: transaction not found':
    'Error creating general ledger for credit: transaction not found',
  'Error creating general ledger for credit payment: missing invoice or bill':
    'Error creating general ledger for credit payment: missing invoice or bill',
  'Error creating general ledger for credit payment: missing transaction':
    'Error creating general ledger for credit payment: missing transaction',
  'Cannot reverse back to draft from current status.':
    'Cannot reverse back to draft from current status.',
  'Invalid GL Code Group': 'Invalid GL Code Group',
  'HBL not found': 'HBL not found',
  'You do not have permission to access shipments':
    'You do not have permission to access shipments',
  'Cannot delete HBL': 'Cannot delete HBL',
  'Invoice not found': 'Invoice not found',
  'Invalid shipment': 'Invalid shipment',
  'Invoice is void': 'Invoice is void',
  'Charge items are not approved': 'Charge items are not approved',
  'Not supporting multiple invoices': 'Not supporting multiple invoices',
  'MBL not found': 'MBL not found',
  'User Not Found': 'User Not Found',
  'Invalid current password': 'Invalid current password',
  subject: 'subject',
  'Email is required': 'Email is required',
  'Missing currency for payment': 'Missing currency for payment',
  'Missing currency for charge item': 'Missing currency for charge item',
  'email is required': 'email is required',
  'Invalid agent': 'Invalid agent',
  'Operator is required': 'Operator is required',
  'Operator cannot be found': 'Operator cannot be found',
  'HBL or MBL not found': 'HBL or MBL not found',
  'Shipment accounts cannot be modified': 'Shipment accounts cannot be modified',
  'Charge Item cannot be zero': 'Charge Item cannot be zero',
  'BL not found': 'BL not found',
  'Charge item not found': 'Charge item not found',
  'Shipment already published': 'Shipment already published',
  'Invalid approval status': 'Invalid approval status',
  'Shipment not deletable': 'Shipment not deletable',
  'missing.host': 'missing.host',
  'Site not found': 'Site not found',
  'Missing email': 'Missing email',
  'Plan not found': 'Plan not found',
  'Invoice/bill not found': 'Invoice/bill not found',
  'Invalid invoice status': 'Invalid invoice status',
  'Invalid bill status': 'Invalid bill status',
  'Cannot delete statement with payments': 'Cannot delete statement with payments',
  'Statement already reconciled': 'Statement already reconciled',
  'Statement not reconciled': 'Statement not reconciled',
  'Statement already approved': 'Statement already approved',
  'Statement payments do not match invoice/bill balances':
    'Statement payments do not match invoice/bill balances',
  'Missing transaction type': 'Missing transaction type',
  'Statement not found': 'Statement not found',
  'Invalid transaction type for statement': 'Invalid transaction type for statement',
  'We were not able to process your request': 'We were not able to process your request',
  'Todo not found': 'Todo not found',
  'Requirement not found': 'Requirement not found',
  'No Container for this id': 'No Container for this id',
  'No MBL with this id': 'No MBL with this id',
  'Carrier not supported': 'Carrier not supported',
  'Could not fetch data from maersk': 'Could not fetch data from maersk',
  'Could not fetch data from Cosco': 'Could not fetch data from Cosco',
  'Could not find the MBL from carrier': 'Could not find the MBL from carrier',
  'Cannot find data from the Carrier': 'Cannot find data from the Carrier',
  'Could not find the MBL from YangMing': 'Could not find the MBL from YangMing',
  'Could not find the MBL from Maersk': 'Could not find the MBL from Maersk',
  'Could not find the MBL from Cosco': 'Could not find the MBL from Cosco',
  'Transaction not found': 'Transaction not found',
  'Bills must belong to the same contact': 'Bills must belong to the same contact',
  'Invalid amounts': 'Invalid amounts',
  'Total transaction amount cannot be negative': 'Total transaction amount cannot be negative',
  'Missing payment amounts': 'Missing payment amounts',
  'Only one statement is allowed': 'Only one statement is allowed',
  'Missing statement': 'Missing statement',
  'Insufficient payment amount': 'Insufficient payment amount',
  'Invalid payment': 'Invalid payment',
  'Insufficient invoice and debit note amount to cover all the bills':
    'Insufficient invoice and debit note amount to cover all the bills',
  'Insufficient bill and credit note amount to cover all the invoices':
    'Insufficient bill and credit note amount to cover all the invoices',
  'Missing user': 'Missing user',
  'Prepayment credit for multi-currencies is not supported yet.':
    'Prepayment credit for multi-currencies is not supported yet.',
  'Transaction already submitted': 'Transaction already submitted',
  'Applying credit for multi-currencies is not supported yet.':
    'Applying credit for multi-currencies is not supported yet.',
  'Invoice balance equals bill balance': 'Invoice balance equals bill balance',
  'No available credits': 'No available credits',
  'Insufficient available credits': 'Insufficient available credits',
  'Transaction already voided': 'Transaction already voided',
  'Invalid payment currency found': 'Invalid payment currency found',
};
