import shipment from './shipment';
import common from './common';
export default {
  'isf.list_title.list': 'ISF List',
  'isf.field.bl_type': shipment['shipment.field.mbl_type'],
  'isf.field.ams_no': 'AMS No.',
  'isf.field.ams_no.format': 'only a maximum of 12 numbers is allowed.',
  'isf.field.ams_scac': 'AMS SCAC',
  'isf.field.isf_no': 'ISF No.',
  'isf.field.isf_type': 'ISF Type',
  'isf.field.shipment_type': 'Shipment Type',
  'isf.field.transaction_type': 'Transaction Type',
  'isf.field.consignee': shipment['shipment.field.consignee'],
  'isf.field.shipper': shipment['shipment.field.shipper'],
  'isf.field.seller': shipment['shipment.field.shipper'],
  'isf.field.agent': shipment['shipment.field.agent'],
  'isf.field.isf_importer_code': 'ISF Importer Code',
  'isf.field.isf_importer_no': 'ISF Importer No. ',
  'isf.field.isf_bond_holder': 'ISF Bond Holder',
  'isf.field.isf_bond_holder_activity_code': 'ISF Bond Holder Activity Code',
  'isf.field.isf_bond_type': 'ISF Bond Type',
  'isf.field.mbl_no': shipment['shipment.field.mbl_no'],
  'isf.field.hbl_no': shipment['shipment.field.hbl_no'],
  'isf.field.carrier': shipment['shipment.field.carrier'],
  'isf.field.carrier_code': 'Carrier Code',
  'isf.field.etd': shipment['shipment.field.etd'],
  'isf.field.eta': shipment['shipment.field.eta'],
  'isf.field.vessel': shipment['shipment.field.vessel'],
  'isf.field.port_of_loading': common['common.field.port_of_loading'],
  'isf.field.port_of_discharge': common['common.field.port_of_discharge'],
  'isf.field.trade_party': 'Trade Party',
  'isf.field.trade_party.creatorButton': 'Add Trade Party',
  'isf.field.trade_party.isf_submission_name': 'ISF Submission Name',
  'isf.field.name': 'Name',
  'isf.field.address': 'Address',
  'isf.field.city': 'City',
  'isf.field.country': 'Country',
  'isf.field.htc_code': 'HTS Code',
  'isf.field.htc_code.format': 'HTS Code format is incorrect, only numbers allowed',
  'isf.field.manufacturer': 'Manufacturer',
  'isf.field.description': 'Description',
  'isf.field.code': 'Code',
  'isf.field.number': 'Number',

  'isf.field.customer_reference': 'Customer Reference / P.O. No.',
  'isf.trade_party.empty': 'No Trade Party',
  'isf.reference.empty': 'No Reference',
  'isf.equipments.empty': 'No Equipments',
  'isf.equipments.no_permission.title': 'ISF Feature is not enabled',
  'isf.equipments.no_permission.content':
    'Please contact customer support to turn on this feature.',
  'isf.field.as_importer': 'As Importer',
  'isf.field.hts_code': 'HTS Code',
  'isf.field.hts_code.creatorButton': 'Add HTS Code',

  'isf.field.reference': 'Reference',
  'isf.field.reference.creatorButton': 'Add Reference',

  'isf.field.equipment': 'Equipment',
  'isf.field.equipment.creatorButton': 'Add Equipment',
  'isf.field.cbp_status': 'CBP status',
  'isf.field.cbp_status_at': 'CBP status date',
  'isf.field.match_status': 'AMS match status',
  'isf.field.match_status_at': 'AMS match date',
  'isf.changelog.type.update': 'Update',
  'isf.changelog.type.submit': 'Submit',
  'isf.changelog.type.new': 'New',
  'isf.changelog.type.receive': 'Deactivate',
  'isf.changelog.type.delete': 'delete',
  'isf.changelog.title': 'ChangeLog',
  'isf.created.success': 'Successfully Created ISF',
  'isf.saved.success': 'Successfully Saved ISF',
  'isf.updated.success': 'Successfully Updated ISF',
  'isf.submitted.success.': 'Successfully Submitted ISF',
  'isf.deleted.success': 'Successfully Deleted ISF',

  'isf.status.new': 'Not Submitted',
  'isf.status.update': 'Update',
  'isf.status.submitted': 'Submitted',
  'isf.status.received': 'Received',
  'isf.status.accepted': 'Accepted',
  'isf.status.rejected': 'Rejected',
  'isf.status.matched': 'Matched',
  'isf.report': 'ISF Report',
  'isf.title.basic_info': 'Basic Information',
  'isf.trade_party.country.help': 'Please modify the zip code and state information in the {link}',
  'isf.trade_party.country.help.link': 'Contacts module',
  'isf.field.trade_party.isf_submission_name.format':
    "Only letters, numbers, spaces, and special characters (&, ', ., -) are allowed, with a maximum length of 100 characters.",
  'isf.error.fields': 'Please check the page and enter the required fields',
};
