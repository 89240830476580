import { DvaModelBuilder, actionCreatorFactory } from 'dva-model-creator';
import { ContactType } from '@/common/type';
import { getContactTypes } from '@/services/contact';

export const CONTACT_OPTIONS_TTL = 1000 * 60 * 60;

export interface ContactModel {
  types: ContactType[] | null;
  loadingTypes: boolean;
}

const actionCreator = actionCreatorFactory('contact');
const loadTypes = actionCreator('loadTypes');
const saveData = actionCreator<{
  types?: ContactType[];
  loadingTypes?: boolean;
}>('saveData');

const initState: ContactModel = {
  loadingTypes: false,
  types: null,
};

export default new DvaModelBuilder(initState, 'contact')
  .takeEvery(loadTypes, function*(_, { call, put }) {
    const response: ContactType[] | { [keys: string]: any } = yield call(getContactTypes);
    if (response && response.length !== undefined) {
      yield put(
        saveData({
          types: response
            .sort((a, b) => {
              return a.value.localeCompare(b.value);
            })
            .map(item => {
              item.value = item.value.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
              return item;
            }) as ContactType[],
          loadingTypes: false,
        }),
      );
    } else {
      yield put(
        saveData({
          loadingTypes: false,
        }),
      );
    }
  })
  .case(saveData, (state, data) => ({
    ...state,
    ...data,
  }))
  .build();

export const actions = {
  loadTypes,
  saveData,
};
