export default {
  'placeholder.global_search': 'Search...',
  'search.type.shipment': 'Shipments',
  'search.type.mbl': 'MB/L',
  'search.type.hbl': 'HB/L',
  'search.type.booking': 'Bookings',
  'search.type.bill': 'Payables',
  'search.type.invoice': 'Receivables',
  'search.type.dc-note': 'Credit Notes/Debit Notes',
  'search.type.statement': 'Statements',
  'search.type.transaction': 'Transactions',
  'search.type.shipment-container': 'Container',
  'search.filters': 'Filters',
};
