export default {
  'admin.employee.create.success': 'Successfully added new user',
  'admin.employee.update.success': 'Successfully updated user',
  'admin.employee.disable.success': 'Successfully disabled user',
  'admin.employee.enable.success': 'Successfully enabled user',
  'admin.color.update.success': 'Successfully updated colors',
  'admin.employee.disable.confirm': 'Are you sure you want to disable this user?',
  'admin.employee.enable.confirm': 'Are you sure you want to enable this user?',
  'admin.employee.filed.name': 'Name',
  'admin.employee.filed.type': 'Type',
  'admin.employee.filed.permissions': 'Permissions',
  'admin.employee.filed.type.placeholder': 'Please Select type',
  'admin.employee.title': 'Employee',
  'admin.employee.add.disabled': 'Please contact SmartForwarder to add new user',
};
