import { DvaModelBuilder, actionCreatorFactory } from 'dva-model-creator';
import { history } from 'umi';
import { getUserInfo, login as loginAPI, logout as logoutAPI } from '@/services/user';
import token from '@/utils/token';
import { UserModel, UserData } from '@/common/type';
import { get } from 'lodash';

const actionCreator = actionCreatorFactory('user');
const activate = actionCreator<{ user: UserData; jwt: string }>('activate');
const login = actionCreator<{
  identifier: string;
  password: string;
  shop: string;
  remember?: boolean;
  invite?: string;
}>('login');
const logout = actionCreator('logout');
const clearUser = actionCreator('clearUser');
const fetchCurrent = actionCreator<string | undefined>('fetchCurrent');
const saveData = actionCreator<UserData | undefined>('saveData');
const setErrorMessage = actionCreator<string | undefined>('setErrorMessage');
const setNewMessage = actionCreator<boolean>('setNewMessage');
const setShowTrialAgreement = actionCreator<boolean>('setShowTrialAgreement');

const initState: UserModel = {
  status: undefined,
  data: undefined,
  error: undefined,
  newMessage: false,
  showTrialAgreement: false,
};

function redirect(user?: UserData, params?: string | { [key: string]: string }) {
  const urlParams = new URLSearchParams(window.location.search);
  let redirect = urlParams.get('redirect');
  // const url = new URL(window.location.href);
  // if (redirect) {
  //   const redirectUrlParams = new URL(redirect);
  //   if (redirectUrlParams.origin === url.origin) {
  //     redirect = redirect.substr(url.origin.length);
  //     if (redirect.match(/^\/.*#/)) {
  //       redirect = redirect.substr(redirect.indexOf('#') + 1);
  //     }
  //   } else {
  //     redirect = null;
  //   }
  // }
  if (!redirect && user) {
    if (params && typeof params !== 'string' && params.invite) {
      redirect = `/invites?t=${params.invite}`;
    }
    if (redirect && params && typeof params === 'string') {
      redirect = `${redirect}?${params}`;
    }
  }
  history.replace(redirect || `/${params && typeof params === 'string' ? `?${params}` : ''}`);
}

export default new DvaModelBuilder(initState, 'user')
  .takeEvery(activate, function*(payload, { call, put }) {
    if (payload.jwt) {
      token.save(payload.jwt);
    }
    yield put(saveData(payload.user));
    redirect(payload.user, 'activated=1');
  })
  .takeEvery(login, function*(payload, { call, put }) {
    if (token.get()) {
      token.remove();
    }
    const { invite, ...loginPayload } = payload;
    let response;
    try {
      response = yield call(loginAPI, loginPayload);
    } catch (error) {
      response = { error };
    }
    // Login successfully
    if (response && response.user) {
      if (response && response.jwt) {
        token.save(response.jwt);
      }
      yield put(saveData(response.user));
      if (response.user) {
        yield put(setNewMessage(!!response.user.new_message));
      }
      yield put(setShowTrialAgreement(!!response.user.show_trial_user_agreement));
      redirect(response.user, invite ? { invite } : undefined);
    } else {
      if (response && response.error) {
        if (get(response, 'data[0].messages[0].message')) {
          yield put(setErrorMessage(get(response, 'data[0].messages[0].message')));
        } else {
          yield put(
            setErrorMessage(
              "Oops! There's a problem signing into your account. Please try again later.",
            ),
          );
        }
      }
      yield put(saveData(undefined));
    }
    return response;
  })
  .takeEvery(logout, function*(_, { call, put }) {
    const response = yield call(logoutAPI);
    if (response && response.type === 'success') {
      yield put(setErrorMessage(undefined));
      yield put(saveData(undefined));
      history.push({
        pathname: '/login',
      });
    }
  })
  .takeEvery(clearUser, function*(_, { call, put }) {
    const response = yield call(logoutAPI);
    if (response && response.type === 'success') {
      yield put(setErrorMessage(undefined));
      yield put(saveData(undefined));
    }
  })
  .takeEvery(fetchCurrent, function*(redirectOnFailed, { call, put }) {
    let user;
    const response = yield call(getUserInfo, {
      errorHandler: async () => {
        if (redirectOnFailed) {
          history.push({
            pathname: '/login',
            query: {
              redirect: redirectOnFailed,
            },
          });
        }
      },
    });
    if (response && !response.error) {
      user = response;
      const umiLocale = localStorage.getItem('umi_locale');
      user.umiLocale = umiLocale;
    } else if (token.get()) {
      token.remove();
    }
    yield put(saveData(user));
    yield put(setShowTrialAgreement(!!user?.show_trial_user_agreement));
    if (response && response.new_message !== undefined) {
      yield put(setNewMessage(response.new_message));
    }
  })
  .case(saveData, (state, data) => ({
    ...state,
    status: !!data,
    data,
  }))
  .case(setErrorMessage, (state, error) => ({
    ...state,
    error,
  }))
  .case(setNewMessage, (state, newMessage) => ({
    ...state,
    newMessage,
  }))
  .case(setShowTrialAgreement, (state, showTrialAgreement) => ({
    ...state,
    showTrialAgreement,
  }))
  .build();

export const actions = {
  fetchCurrent,
  login,
  logout,
  clearUser,
  activate,
  saveData,
  setNewMessage,
  setShowTrialAgreement,
};
