import atob from 'atob';
import { isEmpty } from 'lodash';

const STORAGE_TOKEN_NAME = 'SMARTFORWARDERTOKEN';

/**
 * JWT
 */
export default {
  parse() {
    let token = this.get();
    if (!token) return '';
    try {
      const arr = token.split('.');
      if (arr.length === 3) {
        token = atob(token.split('.')[1]);
      }
      return JSON.parse(token);
    } catch (ex) {
      throw ex;
    }
  },
  check() {
    try {
      const payload = this.parse();
      return !isEmpty(payload);
    } catch (ex) {
      this.remove();
      return false;
    }
  },
  get() {
    return sessionStorage.getItem(STORAGE_TOKEN_NAME);
  },
  save(token: string) {
    sessionStorage.setItem(STORAGE_TOKEN_NAME, token);
  },
  remove() {
    sessionStorage.removeItem(STORAGE_TOKEN_NAME);
  },
};
