export default {
  'charge_item.field.item': 'Item',
  'charge_item.field.vol': 'Vol.',
  'charge_item.field.rate': 'Rate',
  'charge_item.field.code': 'Change Item Code',
  'charge_item.field.description': 'Description',
  'charge_item.local': 'LOC.',
  'charge_item.english': 'ENG.',
  'charge_item.field.p_c': 'P/C',
  'charge_item.field.unit': 'UNIT',
  'charge_item.field.min': 'Min.',
  'charge_item.field.at_cost': 'At Cost',
  'charge_item.field.vol.required': 'Please enter vol.',
  'charge_item.field.rate.required': 'Please enter an unit rate',
  'charge_item.field.item.required': 'Please select item code',
  'charge_item.field.charge_items.message': 'Please add at least one charge items',
  'charge_item.field.amount.required': 'Please enter an amount',
  'charge_item.delete.confirm.title': 'Delete this item?',
  'charge_item.delete.confirm.message': 'Delete this item?',
  'charge_item.extract_form_document': 'Extract from document',
  'charge_item.extract_bill_from_document': 'Create a bill',
  'charge_item.extract_invoice_from_document': 'Create an invoice',
  'charge_item.extract_credit_note_from_document': 'Create a credit note',
  'charge_item.extract_debit_note_from_document': 'Create a debit note',
  'charge_item.create_bill': 'Created bill {code} from this document.',
  'charge_item.create_invoice': 'Created invoice {code} from this document.',
  'charge_item.create_credit_note': 'Created credit note {code} from this document.',
  'charge_item.create_debit_note': 'Created debit note {code} from this document.',
  'charge_item.create_bill_from_document': 'Created bill from this document.',
  'charge_item.create_invoice_from_document': 'Created invoice from this document.',
  'charge_item.create_credit_note_from_document': 'Created credit note from this document.',
  'charge_item.create_debit_note_from_document': 'Created debit note from this document.',
};
