import { ColorType } from '@/common/type';
import request from '@/utils/request';

export const getColors = () => {
  return request<{ data: ColorType[]; total: number; success: boolean }>('/colors');
};

export const getColorByType = (type: string) => {
  return request(`/colors/${type}`);
};

export const createColor = data => {
  return request('/colors', {
    data,
    method: 'POST',
  });
};

export const deleteColor = (id: number) => {
  return request(`/colors/${id}`, {
    method: 'DELETE',
  });
};

export const updateColor = (id: number, data: ColorType) => {
  return request(`/colors/${id}`, {
    data,
    method: 'PUT',
  });
};
