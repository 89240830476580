import shipment from './shipment';
import common from './common';
export default {
  'ams.field.scac': 'SCAC',
  'ams.field.vessel': shipment['shipment.field.vessel'],
  'ams.field.vessel_imo': 'Vessel IMO',
  'ams.field.voyage': shipment['shipment.field.voyage'],
  'ams.field.vessel_country_code': 'Vessel Country Code',
  'ams.field.eta': common['common.field.eta'],
  'ams.field.etd': common['common.field.etd'],
  'ams.field.carrier': shipment['shipment.field.carrier'],
  'ams.field.port_of_discharge': common['common.field.port_of_discharge'],
  'ams.field.port_of_discharge_code': 'POD Code',
  'ams.field.port_of_loading': common['common.field.port_of_loading'],
  'ams.field.mode_of_transport': 'Mode Of Transport',
  'ams.field.consignment_type': 'Consignment Type',
  'ams.field.issuer_scac': 'Issuer SCAC',
  'ams.field.status': 'Status',
  'ams.field.paperless_inbond_participant': 'Paperless In-bond Participant',
  'ams.title.bill_of_lading': 'Billing Of Lading Summary',
  'ams.field.created_at': 'Created At',
  'ams.field.updated_at': 'Updated At',
  'ams.field.ams_no': 'AMS No.',
  'ams.field.report_type': 'Report Type',
  'ams.field.filing_type': 'Filing Type',
  'ams.list_title.list': 'AMS',
  'aci.list_title.list': 'ACI',
  'ams.bill_of_lading.creatorButton': 'Add Bill Of Lading',
  'ams.title.attach': 'Attach',
  'ams.title.detach': 'Detach',
  'ams.saved.success': 'Successfully Saved AMS',
  'ams.updated.success': 'Successfully Updated AMS',
  'ams.submitted.success': 'Successfully Submitted AMS',
  'ams.deleted.success': 'Successfully Deleted AMS',
  'ams.detach.success': 'Successfully Detach Bol',
  'ams.attach.success': 'Successfully Attach Bol',
  'ams.detach.confirm.text': 'Are you sure to detach this BOL?',
  'ams.last_cbp_status': 'LAST CBP STATUS',
  'ams.amend': 'Amend',
  'aci.saved.success': 'Successfully Saved ACI',
  'aci.updated.success': 'Successfully Updated ACI',
  'aci.submitted.success': 'Successfully Submitted ACI',
  'aci.deleted.success': 'Successfully Deleted ACI',
  'ams.amend.confirm.text': 'Are you sure to amend this?',
  'aci.report': 'ACI Report',
};
