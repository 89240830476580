export default {
  'document.delete.success': 'Successfully deleted Document',
  'document.delete.confirm': 'Are you sure to delete this document?',
  'document.upload.success': 'Successfully uploaded Document',
  'document.update.success': 'Successfully updated Document',
  'document.add_from_shipment': 'Add from Shipment',
  'document.add_to_shipment': 'Add to Shipment',
  'document.add_to_shipment.confirm': 'Are you sure to add this document to shipment?',
  'document.add_to_shipment.success': 'Successfully Add Document to Shipment!',
  'document.add_to_hbl': 'Add to HBL/HAWB',
  'document.add_to_hbl.confirm': 'Are you sure to Add this document to HBL/HAWB?',
  'document.add_to_hbl.success': 'Successfully Add Document to HBL/HAWB!',
  'document.field.original_name': 'Original Name',

  'document.upload.title': 'Upload Document',
  'document.field.name': 'Name/Document No.',
  'document.field.name.required': 'Please enter name/document no.',
  'document.field.private': 'Private',
  'document.field.private.tooltip': 'only members from your team can see it',
  'document.field.description': 'Description',
  'document.open_document': 'Open Document',
  'document.title.document_info': 'Document Info',
  'document.title.documents': 'Documents',
  'document.title.email_invoice': 'Email invoice',
  'document.title.email_invoice_code': 'Email invoice {code}',
};
